:root {
	--black: #333;
	--white: white;
	--gold: #e3c936;
	--red2: #a02226;
	--blue: #005c91;
	--sienna: #c04121;
	--bright-blue: #0088cd;
	--blue4: #a5d4ed;
	--button-white: white;
	--blue2: #0082c9;
	--brown3: #eadfc7;
	--brown: #a1855e;
	--blue3: #2c9bd4;
	--red: #d2232a;
	--red-iowa: #d1252a;
	--blue5: #cde8f6;
	--brown2: #c6a77b;
}

.w-checkbox {
	margin-bottom: 5px;
	padding-left: 20px;
	display: block;
}

.w-checkbox:before {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-checkbox:after {
	content: " ";
	clear: both;
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-checkbox-input {
	float: left;
	margin: 4px 0 0 -20px;
	line-height: normal;
}

.w-checkbox-input--inputType-custom {
	border: 1px solid #ccc;
	border-radius: 2px;
	width: 12px;
	height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
	background-color: #3898ec;
	background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
	box-shadow: 0 0 3px 1px #3898ec;
}

body {
	color: var(--black);
	font-family: Playfair Display, sans-serif;
	font-size: 18px;
	line-height: 1.4;
}

img {
	width: auto;
	max-width: 100%;
	display: inline-block;
}

.section-outer {
	justify-content: center;
	align-items: center;
	width: 100%;
	display: flex;
}

.section.end {
	background-color: var(--black);
	align-items: flex-end;
	height: 100vh;
}

.book_object {
	width: 350px;
	transform: rotate(0);
	box-shadow: -6px 10px 20px -3px #0000004d;
}

.image {
	z-index: 10;
	width: 100%;
	height: 100%;
	position: relative;
}

.depth-bottom {
	background-color: #74661b;
	width: 100%;
	height: 4px;
	display: none;
	position: absolute;
	top: auto;
	bottom: -4px;
	left: -2px;
	transform: skew(-45deg);
}

.depth-left {
	background-color: #e3c936;
	width: 4px;
	height: 100%;
	display: none;
	position: absolute;
	inset: 2px auto 0% -4px;
	transform: skew(0deg, -45deg);
}

.book_container {
	background-color: #ececec00;
	align-items: flex-start;
	width: 100%;
	max-width: 1280px;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 40px;
	padding-bottom: 120px;
	display: flex;
}

.book_description {
	width: 100%;
	height: 100%;
	padding: 0 48px 48px 0;
}

.book_object-wrapper {
	perspective: 1200px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding-top: 120px;
	padding-left: 60px;
	display: flex;
	position: sticky;
	top: 124px;
}

.book_description-wrapper {
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-top: 100px;
	display: flex;
}

.section_book {
	z-index: 1000;
	background-color: var(--white);
	position: relative;
}

.section_book.is__yellow {
	background-color: var(--gold);
	background-image: none;
}

.section_book.is_black {
	background-color: #cde8f680;
	display: flex;
}

.space_container {
	height: 30vh;
}

.book_description-title.is_dark-text {
	color: #333;
	font-size: 28px;
}

.book_description-year {
	color: var(--red2);
	margin-top: 8px;
	margin-bottom: 40px;
	margin-left: 0;
	padding-left: 0;
	font-family: Poppins, sans-serif;
	font-size: 18px;
	font-weight: 500;
}

.book_description-year.is_dark-text {
	color: var(--black);
}

.book_description-text {
	color: var(--black);
	margin-top: 48px;
	margin-bottom: 24px;
	font-family: Noto Serif Display, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.8;
	display: none;
}

.book_description-text.is__text-dark {
	color: var(--black);
}

.book_description-title {
	color: var(--blue);
	letter-spacing: -1px;
	margin-bottom: 0;
	padding-left: 0;
	font-family: Merriweather, serif;
	font-weight: 700;
}

.link {
	color: var(--black);
}

.link.is_white {
	color: var(--white);
}

.form {
	flex-direction: column;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.section_left-bar {
	z-index: 99999;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80vh;
	display: flex;
	position: fixed;
	inset: 0% auto auto 0%;
	overflow: hidden;
}

.anchors_wrapper {
	flex-direction: column;
	align-items: flex-start;
	width: 32px;
	display: flex;
}

.anchor {
	background-color: var(--white);
	cursor: pointer;
	border-radius: 10px;
	width: 24px;
	height: 4px;
	margin-top: 4px;
	margin-bottom: 4px;
}

.anchor.w--current {
	background-color: var(--sienna);
	width: 32px;
}

.anchor_home {
	filter: invert(55%);
	justify-content: center;
	align-items: center;
	width: 14px;
	margin-top: 4px;
	margin-bottom: 8px;
	transition: filter 0.3s;
	display: flex;
}

.anchor_home.w--current {
	opacity: 0.85;
	filter: invert();
	transition-duration: 0.5s;
}

.anchor_icon {
	width: 100%;
}

.anchor_end {
	background-color: #c5c5c5;
	justify-content: center;
	align-items: center;
	width: 14px;
	height: 14px;
	margin-top: 6px;
	margin-bottom: 4px;
	transition: background-color 0.3s;
	display: flex;
}

.anchor_end.w--current {
	opacity: 0.85;
	filter: invert(0%);
	background-color: #fff;
	width: 14px;
	height: 14px;
}

.end_icon {
	color: #000;
	background-color: #000;
	width: 14px;
	height: 14px;
}

.logo_wrap {
	text-align: center;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-left: 24px;
	padding-right: 24px;
}

.book-list__item {
	perspective: 1392px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.hero_book {
	perspective: 1200px;
	transform-style: preserve-3d;
	justify-content: flex-end;
	width: 600px;
	margin-left: auto;
	margin-right: auto;
	transform: rotateX(57deg) rotateY(0) rotateZ(0);
}

.hero_book-cover,
.book-cover_img {
	width: 100%;
}

.section_book-list {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.book_description-info {
	margin-top: 36px;
}

.book_description-item {
	border-bottom: 1px solid var(--black);
	text-align: left;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 4px;
}

.book_description-item.is_first {
	border-top: 1px solid #33333359;
	border-bottom-color: #33333359;
	padding-top: 20px;
	padding-bottom: 20px;
}

.book_description-item.is_first.secondrow {
	border-top-width: 0;
	padding-top: 0;
}

.book_description-item.is_dark {
	border-bottom-color: var(--black);
	color: #fff;
}

.book_description-item.is_dark.is_first {
	border-top-color: var(--black);
}

.book_description-item.is_first-copy {
	border-top: 0px solid var(--black);
	border-bottom-color: #33333359;
	padding-top: 20px;
	padding-bottom: 20px;
}

.book_description_quotes {
	color: var(--black);
	font-family: PT Sans, sans-serif;
	font-size: 14px;
	font-weight: 400;
}

.book_description_quotes.is__footnote {
	border-top: 1px solid var(--black);
	opacity: 0.65;
	color: var(--black);
	margin-top: 48px;
	padding-top: 12px;
	display: none;
}

.book_description_quotes.is_dark-text {
	color: var(--black);
}

.book_description_quotes.is__footnote-dark {
	border-top: 1px solid var(--black);
	opacity: 0.65;
	color: var(--black);
	margin-top: 48px;
	padding-top: 12px;
}

.shadow {
	z-index: -1;
	filter: blur(50px);
	background-color: #00000073;
	width: 80%;
	height: 80%;
	position: absolute;
	inset: 22% auto 0% -18%;
}

.hero_container {
	color: #c5c5c5;
	text-align: center;
	cursor: grab;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 1280px;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	display: flex;
	position: relative;
}

.section_hero {
	z-index: 10;
	background-color: var(--blue);
	background-image: url("/20181016082423/assets/images/Header-Farm-graphic-slider2.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 75vh;
	display: flex;
	position: relative;
}

.hero_sub-heading {
	color: var(--white);
	letter-spacing: 0;
	text-transform: capitalize;
	background-color: #0000;
	margin-top: 24px;
	font-family: Poppins, sans-serif;
	font-size: 4vw;
	font-weight: 200;
	line-height: 1;
	display: none;
	position: relative;
}

.hero_heading {
	color: var(--white);
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Noto Serif Display, sans-serif;
	font-size: 10vw;
	font-weight: 700;
	line-height: 1;
}

.footer_bottom {
	background-color: #007abb;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	margin-bottom: 0;
	padding-top: 40px;
	padding-bottom: 40px;
	display: flex;
}

.footer_heading {
	color: var(--white);
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Noto Serif Display, sans-serif;
	font-size: 42px;
	font-weight: 700;
	line-height: 1;
}

.hero_info-wrapper {
	width: 100%;
	display: none;
}

.hero_title-wrapper {
	width: 100%;
}

.hero_top-wrapper {
	width: 100px;
	height: 100px;
	display: none;
}

.hero_info-item {
	width: 50%;
	padding-bottom: 10px;
	padding-left: 16px;
	padding-right: 16px;
}

.hero_info-item.is_25 {
	border-left: 1px solid #c5c5c5;
	flex-direction: column;
	justify-content: flex-end;
	width: 40%;
	padding-bottom: 24px;
	padding-left: 24px;
	padding-right: 24px;
}

.hero_info-item.is_25-copy {
	border-left: 1px solid #c5c5c5;
	flex-direction: column;
	justify-content: flex-end;
	width: 25%;
	padding-bottom: 24px;
	padding-left: 24px;
	padding-right: 24px;
	display: none;
}

.hero_info-text {
	color: var(--white);
	text-align: left;
	letter-spacing: 1px;
	margin-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 16px;
}

.section_right-bar {
	z-index: 9000;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80vh;
	display: flex;
	position: fixed;
	inset: 0% 0% 0% auto;
}

.lower {
	text-transform: lowercase;
}

.footer_wrapper {
	background-color: var(--black);
	justify-content: center;
	align-items: center;
	height: 80vh;
	display: none;
}

.footer-outer {
	background-color: var(--black);
	color: var(--blue);
	height: auto;
}

.footer_text-title {
	color: #c5c5c5;
	letter-spacing: 4px;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 24px;
	font-family: PT Sans, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 1;
	position: relative;
}

.form_field {
	border-style: solid;
	border-width: 1px;
	border-color: var(--black) var(--black) #c5c5c5;
	color: #fff;
	background-color: #0000;
	height: 50px;
	font-family: PT Sans, sans-serif;
	font-size: 24px;
	display: flex;
}

.form_field:active {
	border-bottom-color: #fff;
}

.form_field:focus {
	border-color: #0000 #0000 #fff;
}

.form_field::placeholder {
	color: #c5c5c5;
	text-transform: uppercase;
	font-size: 14px;
}

.form_text-field {
	color: #fff;
	background-color: #0000;
	border: 1px solid #c5c5c5;
	height: 100px;
	margin-top: 48px;
	font-family: PT Sans, sans-serif;
	font-size: 24px;
	display: flex;
}

.form_text-field:active,
.form_text-field:focus {
	border-color: #fff;
}

.form_text-field::placeholder {
	color: #c5c5c5;
	text-transform: uppercase;
	font-size: 14px;
}

.submit-button {
	background-color: var(--gold);
	color: var(--black);
	letter-spacing: 2px;
	text-transform: uppercase;
	width: 100%;
	margin-top: 48px;
	font-family: PT Sans, sans-serif;
	font-weight: 700;
}

.submit-button:hover {
	background-color: #f5d000;
}

.footer_sub-title {
	border-top: 0px solid var(--blue4);
	color: var(--white);
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-top: 16px;
	padding-top: 0;
	font-family: Poppins, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	position: relative;
}

.footer_sub-title h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	font-weight: inherit;
}

.lottie-bg {
	margin-top: -76px;
}

.lottie-wrap {
	opacity: 0.05;
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0%;
	overflow: hidden;
}

.header-mobile-search-reveal {
	background-color: #161e33;
	align-items: center;
	height: auto;
	min-height: auto;
	margin-right: 0;
	transition: background-color 0.2s;
	display: flex;
}

.header-mobile-search-reveal:hover {
	background-color: #9daad4;
}

.account-icon {
	opacity: 1;
	width: 100%;
	margin-right: 0;
	transition: all 0.3s;
	display: none;
	transform: translate(0);
}

.account-icon:hover {
	opacity: 1;
}

.header-contents-wrap {
	background-color: #005c9100;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: none;
	height: 60px;
	padding-left: 4.5rem;
	padding-right: 2.5rem;
	display: flex;
	position: relative;
}

.secondary-nav-list-item {
	float: left;
	letter-spacing: 0.01em;
	border-right: 1px #ffffff40;
	align-self: stretch;
	align-items: center;
	margin-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.navbar {
	z-index: 2000;
	background-color: #007abb;
	border-bottom: 1px #fafbfc40;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: none;
	height: 110px;
	padding: 0;
	display: block;
	overflow: visible;
	box-shadow: 1px 1px 8px 1px #0000001a;
}

.nav-link {
	color: #fff;
	letter-spacing: 0;
	text-transform: capitalize;
	justify-content: center;
	align-items: flex-end;
	width: auto;
	margin-left: 0;
	margin-right: 0;
	padding: 1.3rem 2rem;
	font-family: Playfair Display, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	text-decoration: none;
	transition: background-color 0.3s;
	display: flex;
	position: static;
}

.nav-link:hover {
	color: #fff;
	background-color: #9daad400;
	border-bottom: 3px #3e85ed;
	width: auto;
}

.nav-link.last {
	padding-right: 2rem;
}

.nav-link.first {
	text-transform: none;
	padding-top: 1.3rem;
	padding-bottom: 1rem;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
	font-weight: 400;
	transition-property: background-color;
	transition-duration: 0.3s;
}

.nav-link.first:hover {
	background-color: #9daad400;
	text-decoration: underline;
}

.nav-link.first.upper {
	padding-top: 0;
	padding-bottom: 0;
	font-family: Merriweather, serif;
	font-size: 18px;
	font-weight: 400;
}

.nav-link.first.upper-copy {
	padding-top: 0.3rem;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
}

.nav-link.first.out {
	display: none;
}

.nav-link.first.upper-top {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 14px;
	padding-right: 2rem;
}

.nav-link.first.upper-top-copy {
	background-color: #d1252a;
	border-radius: 10px;
	padding: 0.75rem 1rem;
	font-family: Merriweather, serif;
	font-size: 14px;
	display: none;
}

.nav-link.first.nodesktop {
	display: none;
}

.nav-link.first-copy {
	text-transform: none;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
	font-weight: 400;
	transition-property: background-color;
	transition-duration: 0.3s;
	display: none;
}

.nav-link.first-copy {
	text-transform: none;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
	font-weight: 400;
	transition-property: background-color;
	transition-duration: 0.3s;
}

.nav-link.first-copy:hover {
	background-color: #9daad400;
	text-decoration: underline;
}

.nav-link.first-copy.upper {
	padding-top: 0;
	padding-bottom: 0;
	font-family: Merriweather, serif;
	font-size: 18px;
	font-weight: 400;
}

.nav-link.first-copy.upper-copy {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 18px;
}

.nav-link.first-copy.out {
	display: none;
}

.nav-link.first-copy.upper-top {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 14px;
}

.nav-link.first-copy.upper-top-copy {
	background-color: #d1252a;
	border-radius: 10px;
	padding: 0.75rem 1rem;
	font-family: Merriweather, serif;
	font-size: 14px;
	display: none;
}

.nav-link.first-copy-out {
	text-transform: none;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
	font-weight: 400;
	transition-property: background-color;
	transition-duration: 0.3s;
	display: none;
}

.nav-link.first-copy-out {
	text-transform: none;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
	font-weight: 400;
	transition-property: background-color;
	transition-duration: 0.3s;
}

.nav-link.first-copy-out:hover {
	background-color: #9daad400;
	text-decoration: underline;
}

.nav-link.first-copy-out.upper {
	padding-top: 0;
	padding-bottom: 0;
	font-family: Merriweather, serif;
	font-size: 18px;
	font-weight: 400;
}

.nav-link.first-copy-out.upper-copy {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 18px;
}

.nav-link.first-copy-out.out {
	display: none;
}

.nav-link.first-copy-out.upper-top {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 14px;
}

.nav-link.first-copy-out.upper-top-copy {
	background-color: #d1252a;
	border-radius: 10px;
	padding: 0.75rem 1rem;
	font-family: Merriweather, serif;
	font-size: 14px;
	display: none;
}

.top-header-wrapper {
	border-left: 1px #ffffff9e;
	justify-content: flex-end;
	align-items: center;
	width: auto;
	height: 100%;
	margin-left: auto;
	padding: 0;
	display: flex;
}

.header-bar-column {
	flex-grow: 1;
	justify-content: flex-end;
	align-self: stretch;
	align-items: center;
	width: auto;
	height: 100%;
	margin-top: 0;
	margin-bottom: 0;
	display: none;
}

.header-bar-column.header-bar-left-column {
	justify-content: flex-start;
	align-self: center;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 0.875rem;
	line-height: 1.5em;
	display: none;
}

.secondary-nav-list-link {
	float: left;
	opacity: 1;
	color: #fff;
	border-bottom-style: none;
	justify-content: center;
	align-self: stretch;
	align-items: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 0.75rem;
	display: flex;
	position: relative;
}

.secondary-nav-list-link:hover {
	opacity: 1;
	color: #fff;
	background-color: #0000;
}

.secondary-nav-list-link.account {
	width: 1.5rem;
	height: 1.5rem;
	padding-right: 0;
	overflow: hidden;
}

.logo {
	z-index: 10000;
	float: none;
	border-bottom-style: none;
	flex: none;
	order: -1;
	align-self: center;
	align-items: center;
	width: auto;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	font-size: 1.625rem;
	font-weight: 600;
	line-height: 1.25rem;
	display: inline-block;
	position: absolute;
	top: -40px;
	overflow: hidden;
}

.logo:hover {
	color: #fff;
}

.icon-wrapper {
	border-left: 1px #dad8d8;
	align-items: center;
	height: 100%;
	padding-left: 0;
	display: flex;
}

.header-bar-promo-icon {
	width: 1.125em;
	height: 1.125em;
	margin-right: 0.625em;
}

.nav-bar-dropdown {
	color: #3f3f3f;
	letter-spacing: 1px;
	text-transform: uppercase;
	align-items: flex-end;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
	font-family: Montserrat, sans-serif;
	font-size: 11px;
	font-weight: 700;
	transition: background-color 0.3s;
	display: none;
}

.nav-bar-dropdown:hover {
	border-bottom: 5px #3e85ed;
}

.dropdown-link {
	color: #333;
	letter-spacing: 0;
	text-transform: capitalize;
	background-image: url("/20181016082423/assets/images/chevron-right_white.svg");
	background-position: 5%;
	background-repeat: no-repeat;
	background-size: 6px;
	border-bottom: 1px solid #33333308;
	padding: 10px 1.5rem;
	font-size: 0.9rem;
	font-weight: 500;
	transition: transform 0.35s cubic-bezier(0.39, 0.575, 0.565, 1), color 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.dropdown-link:hover {
	color: #ff6a3d;
	background-color: #fafbfc;
	background-image: url("/20181016082423/assets/images/chevron-right_white.svg");
	background-position: 5%;
	background-repeat: no-repeat;
	background-size: 6px;
}

.dropdown-wrap {
	padding: 0 10px 0 0;
}

.dropdown-wrap:hover {
	color: #3e85ed;
}

.dropdown-wrap.w--open {
	background-color: #9daad4;
}

.secondary-nav-icon {
	float: left;
	width: 22px;
	height: 22px;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	display: block;
}

.secondary-nav-icon.account {
	width: 100%;
	height: 100%;
}

.account-link {
	width: 1.7rem;
	height: 1.7rem;
	margin-top: 0.25rem;
	margin-right: 0;
	overflow: hidden;
}

.text-block-5 {
	font-size: 0.85rem;
	font-weight: 400;
	line-height: 1.25em;
}

.text-block-5:hover {
	color: #3eedb6;
}

.header-bar-wrapper {
	color: #fff;
	letter-spacing: 0;
	background-color: #005c9100;
	justify-content: flex-start;
	align-items: stretch;
	max-width: none;
	height: 40px;
	margin-top: 10px;
	padding: 0 2.5rem 0 0;
	display: flex;
	position: relative;
}

.cart-link {
	width: 1.7rem;
	height: 1.7rem;
	margin-top: 0.25rem;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
	display: none;
	overflow: hidden;
}

.logo-image {
	float: none;
	width: 150px;
}

.secondary-nav-list {
	justify-content: flex-end;
	align-self: stretch;
	align-items: center;
	height: 100%;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 1rem;
	line-height: 1.375em;
	display: flex;
}

.nav-link-2 {
	box-shadow: none;
	color: #000;
	text-align: center;
	text-transform: uppercase;
	flex: 1 0 auto;
	margin-right: 1px;
	padding: 1rem 0.5rem;
	font-size: 1.25rem;
	font-weight: 800;
	line-height: 1.375rem;
	display: inline-block;
}

.nav-link-2:hover {
	color: #3128b1;
	background-color: #0000;
	box-shadow: inset 0 -3px #3128b1;
}

.nav-link-2.w--current {
	color: #fff;
	background-color: #0003;
	box-shadow: 0 -3px #fff;
}

.nav-link-2.w--current:hover {
	color: #fff;
	background-color: #0000001a;
}

.nav-menu {
	color: #fff;
	background-color: #0000;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 60px;
	margin-left: auto;
	display: flex;
	position: static;
}

.search-icon {
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/search-white-01.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 17px;
	width: auto;
	height: auto;
	min-height: 3rem;
	padding: 1rem 1.5rem;
	display: none;
}

.search-icon:hover {
	background-image: url("/20181016082423/assets/images/search-white-01.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 17px;
}

.dropdown-list {
	margin-top: -5px;
}

.dropdown-list.w--open {
	background-color: #fff;
	border-top: 4px #1d1e50;
	margin-top: 0;
	top: 62px;
	box-shadow: 1px 1px 5px #00000040;
}

.two-part-button-wrap {
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;
}

.nav-bar-invisible-scroll-block {
	z-index: auto;
	background-color: #f8f4ec;
	width: 100%;
	height: 110px;
	position: absolute;
	inset: 0% 0% auto;
}

.slider-two {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	width: 101%;
}

.icon-7 {
	margin-top: auto;
	margin-bottom: auto;
}

.hero_info-text-2 {
	color: #ececec;
	text-align: left;
	letter-spacing: 1px;
	margin-bottom: 0;
	font-family: PT Sans, sans-serif;
	font-size: 14px;
}

.section_hero-2 {
	z-index: 10;
	background-color: #c04121;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 80vh;
	display: flex;
	position: relative;
}

.slider-inner-content {
	flex-direction: column;
	align-items: flex-start;
	margin-top: 60px;
	display: flex;
}

.right-arrow {
	font-size: 2rem;
	display: block;
}

.hero_heading-2 {
	color: #ececec;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 10vw;
	font-weight: 700;
	line-height: 1;
}

.slider-three {
	background-position: 0 0, 0 0;
	background-size: auto, cover;
}

.slide-inner-content {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	max-width: none;
	height: 100%;
	padding: 0 10rem;
	display: flex;
	position: relative;
	top: 0%;
	left: 0%;
}

.button {
	z-index: 5;
	color: #9daad4;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	cursor: pointer;
	background-color: #161e33;
	background-image: url("/20181016082423/assets/images/white-arrow-icon-01.svg");
	background-position: 87%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 0;
	padding: 0.75rem 3.75rem 0.75rem 1.25rem;
	font-size: 0.85rem;
	font-weight: 500;
	transition: all 0.4s, background-color 0.4s;
	display: none;
	position: static;
	top: auto;
}

.button:hover {
	opacity: 1;
	color: #fff;
	background-color: #9daad4;
}

.slider {
	height: 75vh;
}

.left-arrow {
	font-size: 2rem;
	display: block;
}

.slide-heading {
	color: #fff;
	letter-spacing: 2px;
	text-transform: capitalize;
	max-width: 600px;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 4rem;
	font-weight: 700;
	line-height: 4.15rem;
}

.hero_sub-heading-2 {
	color: #ececec;
	letter-spacing: 9px;
	text-transform: uppercase;
	background-color: #0000;
	margin-top: 24px;
	font-family: PT Sans, sans-serif;
	font-size: 1.5vw;
	font-weight: 400;
	line-height: 1;
	position: relative;
}

.slider-one {
	background-image: linear-gradient(#00000080, #fff0);
}

.text-block {
	color: #fff;
	letter-spacing: 2px;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	font-weight: 400;
	display: flex;
}

.text-block.hero-image {
	letter-spacing: 0;
	text-transform: none;
	margin-bottom: 40px;
	font-size: 1.15rem;
}

.slide-nav {
	font-size: 10px;
	left: 0%;
}

.icon-6 {
	margin-top: auto;
	margin-bottom: auto;
}

.link-2 {
	color: #333;
}

.link-2.is_white {
	color: #ececec;
}

.div-block-175 {
	flex-flow: row;
	justify-content: space-between;
	width: 100%;
	display: none;
}

.promo2box {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	color: #383838;
	background-color: #ececec;
	border-radius: 0;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: auto;
	padding: 20px;
	font-family: Open Sans, sans-serif;
	font-weight: 300;
	text-decoration: none;
	transition: transform 0.45s, all 0.3s;
	display: flex;
}

.promo2box:hover {
	border-radius: 0;
	transform: translate(0, -10px);
	box-shadow: 0 4px 9px 1px #0003;
}

.heading-43 {
	color: var(--black);
	margin-bottom: 10px;
	font-family: Poppins, sans-serif;
	font-size: 20px;
	font-weight: 600;
}

.promo2imgcon {
	align-items: center;
	width: 100%;
	display: flex;
}

.promo2icon {
	width: 70%;
	max-width: none;
	margin-top: 0;
	margin-bottom: 0;
}

.promo2titletext {
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	display: flex;
}

.promocontentwrapper-2 {
	grid-column-gap: 30px;
	grid-row-gap: 30px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.promo2text {
	color: #242424;
	text-align: left;
	width: 90%;
	max-width: none;
	margin-bottom: 10px;
	padding-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	display: flex;
}

.button-2 {
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	background-color: #a02226;
	border: 0 solid #bfff00;
	border-radius: 1.25rem;
	justify-content: flex-start;
	align-items: center;
	width: 130px;
	height: 1.875rem;
	padding-top: 0;
	padding-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 0.625rem;
	transition: all 0.5s;
	display: none;
	position: relative;
}

.button-2:hover {
	color: #fff;
	background-color: #d2232a;
	padding-left: 0;
}

.button-text {
	color: #fff;
	margin-left: 0.625rem;
	margin-right: 0.625rem;
	text-decoration: none;
}

.button-text.white {
	color: var(--white);
	margin-right: 2rem;
	font-family: Poppins, sans-serif;
	font-size: 0.9rem;
	font-weight: 600;
}

.arrow {
	border-style: none;
	border-radius: 0;
	min-width: auto;
	margin-bottom: 0;
	margin-right: 0.25rem;
	padding-top: 0;
	padding-bottom: 0.15625rem;
	position: absolute;
	inset: auto 0% 0% auto;
}

.div-block-5 {
	border-radius: 5px;
	justify-content: center;
	width: 50%;
	height: 30%;
	margin-top: 0;
	display: flex;
	overflow: hidden;
}

.image-3 {
	border-radius: 5px;
	width: 120%;
	max-width: none;
}

.image-3.img-zoom {
	width: 100%;
	transition: all 0.45s;
}

.image-3.img-zoom:hover {
	transform: scale(1.1);
}

.image-4 {
	width: 120px;
	margin-top: 0;
}

.footer_heading-copy {
	color: var(--white);
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Noto Serif Display, sans-serif;
	font-size: 42px;
	font-weight: 700;
	line-height: 1;
	display: none;
}

.client-slideshow {
	background-color: #0000;
	align-items: center;
	height: auto;
	padding-top: 40px;
	padding-bottom: 40px;
}

.clients-arrow {
	color: #296f9e;
	width: 50px;
	font-size: 1.5em;
}

.clients-slide-nav {
	background-color: #0000;
}

.client-logo {
	justify-content: center;
	align-items: center;
	width: auto;
	max-width: none;
	height: auto;
	margin-left: 15px;
	margin-right: 15px;
	display: flex;
}

.section-heading {
	color: #000645;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 65px;
	font-weight: 500;
	line-height: 1.25em;
}

.section-heading.our {
	color: var(--blue);
	letter-spacing: -1px;
	font-family: Merriweather, serif;
	font-size: 32px;
	font-weight: 700;
}

.clients-container {
	z-index: 1;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 60px;
	padding-right: 60px;
	position: relative;
}

.client-logos {
	justify-content: space-around;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	padding-left: 50px;
	padding-right: 50px;
	display: flex;
}

.clients {
	background-color: #fff;
	padding-top: 50px;
	padding-bottom: 30px;
	position: relative;
}

.mapwrap {
	justify-content: center;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 60px;
	display: none;
}

.map {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	width: 750px;
	display: grid;
}

.state1 {
	width: 305px;
	position: relative;
}

.state2 {
	width: 198px;
	position: relative;
	left: 0;
}

.image-5 {
	position: relative;
}

.image-6 {
	position: relative;
	left: -40px;
}

.state3 {
	width: 199px;
	position: relative;
	left: -97px;
}

.state4 {
	width: 243px;
	position: relative;
	top: -46px;
	left: 36px;
}

.state5 {
	width: 265px;
	position: relative;
	top: -57px;
	left: -67px;
}

.state6 {
	width: 168px;
	position: relative;
	top: -56px;
	left: -61px;
}

.state7 {
	width: 137px;
	position: relative;
	top: -113px;
	left: -120px;
}

.state8 {
	width: 213px;
	position: relative;
	top: -114px;
	left: 92px;
}

.state9 {
	width: 258px;
	position: relative;
	top: -120px;
	left: -18px;
}

.state10 {
	width: 134px;
	position: relative;
	top: -110px;
	left: -43px;
}

.map-pin {
	filter: hue-rotate(225deg);
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/interface.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 35px;
	height: 50px;
	transition: all 0.2s;
	position: absolute;
	top: 127px;
	left: 90px;
}

.map-pin:hover {
	filter: hue-rotate(200deg);
	transform: translate(0, -3px);
}

.map-pin.latin-america {
	top: 180px;
}

.map-pin.latin-america._2 {
	z-index: 1;
	filter: none;
	margin-top: 0;
	padding-top: 0;
	display: none;
	top: 50px;
	left: 255px;
}

.map-pin-tooltip {
	background-color: #fff;
	border: 2px #000;
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	height: auto;
	margin-top: -45px;
	padding: 0 10px;
	display: flex;
	position: absolute;
	top: 7px;
	left: -56px;
	right: -56px;
	box-shadow: 1px 1px 10px 2px #00000040;
}

.map-pin-tooltip.north-america {
	flex-flow: column;
	align-items: flex-start;
	width: 350px;
	margin-top: 0;
	padding: 10px 0 20px 20px;
	top: -63px;
	left: -167px;
}

.country {
	color: #b12727;
	text-align: left;
	width: 130px;
	margin-top: 10px;
	padding-left: 0;
	padding-right: 0;
	font-family: Poppins, sans-serif;
	font-size: 20px;
}

.button-2-copy {
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	background-color: #0000;
	border: 0 solid #bfff00;
	border-radius: 1.25rem;
	justify-content: flex-start;
	align-items: center;
	width: 130px;
	height: 1.875rem;
	padding-top: 0;
	padding-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 0.625rem;
	transition: all 0.5s;
	display: none;
	position: relative;
}

.button-2-copy:hover {
	color: #fff;
	background-color: #0000;
	padding-left: 0;
}

.mapwrap2 {
	background-color: #eadfc726;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 10px;
	display: flex;
	position: relative;
}

.image-map {
	width: 550px;
	margin-top: 60px;
	position: relative;
	top: -77px;
}

.promo2text-copy {
	color: #242424;
	text-align: left;
	width: 90%;
	max-width: none;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	display: flex;
}

.text-block-6 {
	color: #094386;
	font-family: Pacifico, sans-serif;
	font-size: 100px;
	font-style: normal;
}

.text-block-6.small {
	font-size: 50px;
	margin-top: 20px;
	text-align: center;
}

.div-block-176,
.sneak-title {
	z-index: -1;
	position: relative;
}

.arrow-image {
	width: 60px;
	position: relative;
	top: -43px;
	right: -385px;
}

.section-2 {
	padding-top: 176px;
	padding-bottom: 176px;
}

.subtitle {
	color: #4a3aff;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	margin-bottom: 8px;
	font-weight: 700;
	line-height: 20px;
}

.subtitle.mg-bottom-16px {
	margin-bottom: 16px;
}

.container-default {
	max-width: 1218px;
	padding-left: 24px;
	padding-right: 24px;
}

.grid-3-columns {
	grid-column-gap: 28px;
	grid-row-gap: 28px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.grid-3-columns.gap-row-48px {
	grid-row-gap: 48px;
}

.inner-container._640px {
	max-width: 640px;
}

.empty-state {
	text-align: center;
	background-color: #fff;
	border: 1px solid #eff0f6;
	border-radius: 11px;
	padding: 26px 24px;
	box-shadow: 0 2px 12px #14142b14;
}

.text-center {
	text-align: center;
}

.product-card-image-wrapper {
	border-radius: 24px;
	position: relative;
	overflow: hidden;
	transform: translate(0);
}

.badge-wrapper-top-right {
	position: absolute;
	inset: 20px 20px auto auto;
}

.badge-primary {
	color: #fff;
	background-color: #4a3aff;
	border-radius: 40px;
	padding: 18px 28px;
	font-size: 18px;
	line-height: 20px;
	display: inline-block;
}

.badge-primary.small {
	padding: 14px 22px;
	font-size: 16px;
	line-height: 18px;
}

.mg-top-32px {
	margin-top: 32px;
}

.text-300 {
	font-size: 22px;
	line-height: 22px;
}

.text-300.medium {
	font-weight: 500;
}

.mg-top-24px {
	margin-top: 24px;
}

.btn-secondary {
	color: #211f54;
	text-align: center;
	transform-style: preserve-3d;
	background-color: #fff;
	border: 1px solid #eff0f6;
	border-radius: 48px;
	padding: 26px 38px;
	line-height: 20px;
	transition: border-color 0.3s, transform 0.3s, background-color 0.3s, color 0.3s;
	box-shadow: 0 4px 10px #14142b0a;
}

.btn-secondary:hover {
	color: #fff;
	background-color: #4a3aff;
	border-color: #4a3aff;
	transform: translate3d(0, -3px, 0.01px);
}

.btn-secondary.width-auto {
	display: inline-block;
}

.btn-secondary.width-auto.no-transition {
	transition: transform 0.3s, background-color 0.3s;
}

.null-arrow {
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
}

.padding-bottom {
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
}

.team_name-copy {
	color: #fff;
	margin-top: 10px;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5;
	display: none;
}

.block-arrow {
	background-color: #0000;
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	width: 80px;
	height: 50px;
	margin-left: 10px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.three-box-content {
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	justify-content: center;
	max-width: 1200px;
	margin: 4rem auto;
	display: none;
}

.blob-2 {
	background-color: #0000;
	border-radius: 99999px;
	width: 40px;
	height: 40px;
	display: block;
	position: absolute;
	inset: auto auto auto 6px;
}

.arrow-stem-2 {
	color: #ed0b08;
	background-color: #c50000;
	width: 68px;
	height: 2px;
}

.heading-59 {
	color: #fff;
	text-align: center;
	text-transform: none;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 34px;
}

.team_item_promo {
	border: 10px solid var(--red2);
	cursor: pointer;
	border-radius: 0;
	width: 50%;
	position: relative;
	overflow: hidden;
	box-shadow: 0 2px 5px #0003;
}

.team_item_promo.first {
	border: 3px solid var(--blue2);
	border-radius: 4px;
}

.text-cta-2 {
	z-index: 1;
	color: #2e2b2b;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 600;
	position: relative;
}

.team_content-2 {
	color: #fff;
	background-color: #111010b0;
	border-radius: 0;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	height: auto;
	padding: 1rem 2rem;
	display: flex;
	position: absolute;
	inset: auto 10% 50%;
}

.text-size-small-copy {
	font-size: 0.875rem;
}

.text-size-small-copy.text-style-muted {
	display: none;
}

.team_image-2 {
	object-fit: cover;
	width: 100%;
	height: auto;
}

.button-with-arrow-copy {
	opacity: 0.8;
	cursor: pointer;
	background-color: #0000;
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	height: 50px;
	margin-top: 0;
	padding-left: 0;
	padding-right: 19px;
	display: none;
	position: relative;
	overflow: hidden;
}

.button-with-arrow-copy:active {
	opacity: 1;
}

.team_description-copy-2 {
	color: #fff;
	margin-bottom: 0;
	font-size: 0.875rem;
	font-weight: 400;
	display: none;
}

.spotlights {
	background-color: #cde8f64d;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
	display: flex;
}

.f-margin-bottom-40 {
	margin-bottom: 0;
}

.f-h3-heading-4 {
	color: var(--blue);
	letter-spacing: -2px;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Merriweather, serif;
	font-size: 48px;
	font-weight: 700;
	line-height: 1.2;
}

.f-avatar-image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.f-margin-bottom-140 {
	margin-bottom: 16px;
}

.f-testimonial-avatar-small {
	background-color: #f3f5fb;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 48px;
	min-width: 48px;
	height: 48px;
	min-height: 48px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.f-margin-bottom-139 {
	margin-bottom: 12px;
}

.f-testimonial-slide {
	margin-right: 20px;
}

.f-container-regular-3 {
	width: auto;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.f-testimonial-background {
	background-color: var(--brown3);
	width: 100%;
	height: 30%;
	position: absolute;
	inset: auto 0% 0%;
}

.f-testimonial-right-a {
	background-color: var(--sienna);
	color: #fff;
	border: 1px solid #e4e6f1;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	padding: 6px;
	transition: all 0.3s;
	display: flex;
	bottom: 0px;
	right: 0px;
	top: auto;
	z-index: 10000;
}

.f-testimonial-right-a:hover {
	border-color: var(--red2);
	background-color: var(--red2);
	color: #fff;
}

.testimonial-4 {
	background-color: #fff;
	padding: 60px 5% 100px;
	position: relative;
	overflow: hidden;
}

.f-testimonial-slider-large {
	background-color: #0000;
	justify-content: flex-start;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	margin-top: 80px;
	display: flex;
	overflow: clip;
}

.f-testimonial-left-a {
	z-index: 10;
	color: #393b6a;
	background-color: #0000;
	border: 1px solid #ccb88c;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	margin-top: 0;
	padding: 6px;
	transition: all 0.3s;
	display: flex;
	top: auto;
	bottom: 0;
	right: 50px;
	left: auto;
	z-index: 10000;
}

.f-testimonial-left-a:hover {
	background-color: var(--sienna);
	color: #fff;
}

.f-paragraph-regular-3 {
	letter-spacing: -0.02em;
	margin-bottom: 0;
	font-size: 16px;
	line-height: 1.8;
}

.f-paragraph-regular-3.f-text-weight-medium {
	font-weight: 500;
}

.f-paragraph-small-2 {
	letter-spacing: -0.01em;
	margin-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	line-height: 1.8;
}

.f-testimonial-slider-mask {
	width: 300px;
	overflow: visible;
}

.f-icon-regular-3 {
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	transition: color 0.2s;
	display: flex;
}

.f-heading-detail-small-5 {
	color: #6b7094;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	line-height: 24px;
	display: none;
}

.f-slide-nav-hidden {
	justify-content: center;
	display: flex;
	bottom: 70px;
}

.f-testimonial-card {
	color: #160042;
	background-color: #fff;
	border-radius: 8px;
	padding: 32px;
	box-shadow: 30px 20px 30px 1px #393b6a0f;
}

.f-testimonial-star-wrapper {
	grid-column-gap: 4px;
	margin-bottom: 16px;
	display: none;
}

.f-testimonial-author {
	grid-column-gap: 16px;
	align-items: center;
	text-decoration: none;
	display: none;
}

.f-testimonial-author:hover {
	border-color: #0000;
	box-shadow: 23px 23px 64px -16px #393b6a0f;
}

.image-8 {
	margin-top: 50px;
	width: 300px;
}

.div-block-177 {
	width: 80%;
	display: flex;
}

.div-block-178 {
	z-index: 10;
	width: 20%;
	position: relative;
}

.div-block-179,
.maskot,
.promo {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 0.25fr 1fr;
	grid-auto-columns: 1fr;
	display: flex;
}

.mascot {
	z-index: 10;
	width: 20%;
	position: relative;
}

.testimonials-carousel {
	width: 80%;
	display: flex;
}

.image-9 {
	background-color: var(--red2);
}

.div-block-180 {
	flex-flow: column;
	align-items: center;
	display: flex;
}

.div-block-181 {
	grid-column-gap: 40px;
	grid-row-gap: 40px;
	color: var(--white);
	text-align: center;
	margin-bottom: 20px;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	display: flex;
}

.logo-footer {
	border-bottom: 1px solid var(--blue4);
	margin-bottom: 20px;
	padding-bottom: 10px;
}

.text-span,
.text-span-2 {
	font-weight: 700;
}

.div-block-182 {
	border-top: 1px solid var(--blue4);
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	padding-top: 20px;
	display: flex;
}

.copywright {
	margin-left: 5%;
	display: flex;
}

.footer-grip-link {
	cursor: pointer;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 130px 29px;
	width: 133px;
	min-width: 0;
	height: 29px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	text-decoration: none;
	transition: all 0.2s;
}

.footer-grip-link.white-logo {
	background-size: 150px 29px;
	width: 140px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
}

.grip-logo-text-box-2 {
	color: #000;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
	font-size: 0.5rem;
	font-weight: 700;
	line-height: 0.5rem;
	text-decoration: none;
}

.grip-logo-text-box-2.webdevby-white {
	color: #fff;
	letter-spacing: 0;
	width: auto;
	font-family: Poppins, sans-serif;
	font-size: 0.6rem;
	font-weight: 400;
}

.div-block-183 {
	margin-right: 5%;
}

.footer_title {
	color: #fff;
	font-size: 1.125rem;
	font-weight: 600;
}

.footer-social-icon-image {
	float: left;
	color: #025e8a;
}

.footer-social-icon-image.youtube {
	padding: 1px;
}

.footer_links-col-4 {
	color: #374957;
	flex-direction: column;
	align-items: flex-start;
	display: none;
}

.footer-social-icons-wrap {
	justify-content: center;
	display: flex;
}

.div-block-17 {
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	display: flex;
}

.footer-social-icon-link {
	float: none;
	background-color: var(--blue2);
	color: #2a779b;
	border-radius: 20px;
	width: 2.5rem;
	min-width: 0;
	height: 2.5rem;
	margin: 0.5rem 0.5rem 0.25rem 0;
	padding: 4px;
	transition: all 0.2s;
	display: none;
}

.footer-social-icon-link:hover {
	background-color: var(--brown);
}

.footer-social-icon-link.first {
	background-color: var(--blue);
	margin-top: 0;
	margin-right: 0;
	display: flex;
}

.footer-social-icon-link.first:hover {
	background-color: var(--blue3);
}

.footer_link {
	color: #fff;
	margin-top: 0.5rem;
	font-weight: 300;
	transition: opacity 0.2s;
}

.footer_link:hover {
	opacity: 0.7;
	color: #fff;
}

.link-list-title {
	color: #fff;
	text-align: center;
	text-transform: capitalize;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	font-size: 16px;
	font-weight: 700;
	display: flex;
}

.footercol {
	flex-flow: column;
	align-items: center;
	width: 33%;
	display: flex;
}

.footer-info {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	color: var(--white);
	text-align: center;
	justify-content: center;
	max-width: 1200px;
	width: 100%;
	margin-bottom: 20px;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	display: flex;
}

.rowtitle {
	width: 100%;
	max-width: 1200px;
	margin-bottom: 16px;
}

.large-card-section {
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	perspective: 1000px;
	background-color: #eff8fc;
	flex-flow: wrap;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	align-items: center;
	width: auto;
	max-width: 1440px;
	min-height: 30vh;
	padding-top: 3%;
	padding-bottom: 3%;
	display: none;
}

.card-wrapper {
	flex-direction: column;
	align-items: center;
	margin: 0 1rem 2rem;
	line-height: 28px;
	display: flex;
	position: relative;
}

.overflow-wrapper {
	perspective: 1000px;
	height: 255px;
	overflow: visible;
}

.large-card-background {
	z-index: 1;
	object-fit: cover;
	transform-style: preserve-3d;
	flex-direction: column;
	align-items: center;
	width: 322px;
	height: 243px;
	margin: 1rem;
	transition: transform 0.4s;
	display: flex;
	position: relative;
	box-shadow: 2px 0 12px #1f1f1f38;
}

.large-card-background:hover {
	transform: rotateX(0) rotateY(-20deg) rotateZ(0);
}

.large-card-text-wrap {
	z-index: 2;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	line-height: 28px;
	display: flex;
	position: absolute;
	inset: auto -1rem 0.8rem auto;
}

.large-card-header {
	color: #1f1f1f;
	letter-spacing: 1px;
	text-transform: capitalize;
	background-color: #fcfcfc;
	margin-bottom: 0.6rem;
	padding: 0.2rem 0.7rem;
	font-family: Pacifico, sans-serif;
	font-size: 1.5rem;
	font-weight: 400;
	box-shadow: 2px 2px 18px -9px #00000080;
}

.card-subtext {
	color: #1f1f1f;
	text-align: left;
	letter-spacing: 1px;
	text-transform: uppercase;
	cursor: pointer;
	background-color: #fcfcfc;
	margin-bottom: 1rem;
	padding: 0.2rem 0.6rem;
	font-family: Poppins, sans-serif;
	font-size: 0.8rem;
	font-weight: 500;
	line-height: 1.7;
	transition: all 0.35s;
	display: none;
	box-shadow: 2px 2px 18px #0000004d;
}

.card-subtext:hover {
	color: var(--white);
	background-color: #a02226;
}

.advertisers_img {
	width: 200px;
	max-width: none;
	height: 167px;
}

.btn_11 {
	background-color: var(--red);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	color: #000;
	border: 1px solid #ffffff3d;
	padding: 1.2rem 2rem;
	font-size: 1rem;
	line-height: 1;
	transition: all 0.2s;
	overflow: hidden;
}

.btn_11_content {
	z-index: 1;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	display: flex;
	position: relative;
	overflow: hidden;
}

.btn_11_text {
	color: var(--white);
	margin-right: 2rem;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	text-decoration: underline;
}

.btn_11_arrow_wrapper {
	padding-left: 0;
	display: inline-block;
	overflow: hidden;
}

.btn_11_arrow {
	width: 2rem;
	height: 2rem;
}

.button-3 {
	color: #040126;
	text-align: center;
	letter-spacing: -0.03rem;
	background-color: #9adbff;
	padding: 0.75rem 1.5rem;
	font-weight: 500;
	text-decoration: none;
	overflow: visible;
}

.button-3.c-11 {
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
	background-color: var(--red);
	border-radius: 0.4rem;
	justify-content: center;
	align-items: center;
	padding-left: 2rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-3.c-11-copy {
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
	border: 2px solid var(--white);
	background-color: var(--red);
	border-radius: 0.4rem;
	justify-content: center;
	align-items: center;
	padding-left: 2rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	display: none;
	position: relative;
	overflow: hidden;
}

.button-3.c-11-topbar {
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
	border: 2px solid var(--white);
	background-color: var(--red);
	border-radius: 0.4rem;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-3.c-11-topbar.gift,
.button-3.c-11-topbar-mobile.gift {
	margin-right: 2rem;
}

.button-3.newslettersub {
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
	border: 2px solid var(--white);
	background-color: var(--red);
	border-radius: 0.4rem;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
	padding: 0.5rem 1rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
}


.button-3.footersub {
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
	border: 2px solid var(--white);
	background-color: var(--red);
	border-radius: 0.4rem;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0.5rem 1rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-3.c-11-topbar-inside,
.button-3.c-11-topbar-inside2 {
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
	border-style: solid;
	border-width: 2px 2px 2px 0;
	border-color: var(--white);
	background-color: var(--red);
	border-radius: 0.4rem;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-3.c-11-topbar-inside-copy {
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
	border-style: solid;
	border-width: 2px 2px 2px 0;
	border-color: var(--white);
	background-color: var(--blue2);
	border-radius: 0.4rem;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-3.c-11-topbar-mobile {
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
	border: 2px solid var(--white);
	background-color: var(--red);
	border-radius: 0.4rem;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.btn-bg {
	background-color: #5b96fe;
	border-radius: 100%;
	width: 120%;
	min-width: 5rem;
	height: 175%;
	display: block;
	position: absolute;
	inset: auto;
}

.btn-bg.c-10 {
	background-color: var(--red2);
	border-radius: 50rem;
	align-self: center;
	width: 130%;
	min-width: auto;
	height: 120%;
	inset: auto;
}

.btn-bg.c-10.main-sub {
	margin-top: 60px;
}

.btn-bg.footerbtn {
	background-color: var(--red2);
	border-radius: 50rem;
	align-self: center;
	width: 130%;
	min-width: auto;
	height: 120%;
	inset: auto;
}

.btn-bg.footerbtn.main-sub {
	margin-top: 60px;
}

.btn-bg.c-10-copy {
	background-color: var(--red2);
	border-radius: 50rem;
	align-self: center;
	width: 130%;
	min-width: auto;
	height: 120%;
	inset: auto;
}

.btn-bg.c-10-copy.main-sub {
	margin-top: 60px;
}

.btn-txt {
	color: var(--white);
	letter-spacing: 0;
	text-transform: uppercase;
	font-family: Merriweather, serif;
	font-size: 14px;
	font-weight: 400;
	position: relative;
}

.btn-icon-wrapper {
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
	overflow: hidden;
}

.btn-icon-arrow {
	color: var(--white);
	width: 1.2rem;
	height: 1.2rem;
}

.btn-icon-arrow.hover {
	position: absolute;
	top: 0;
	left: -100%;
}

.body {
	font-family: Poppins, sans-serif;
}

.div-block-184 {
	width: 160px;
}

.button-subscribe {
	width: 160px;
	display: none;
}

.button-subscribe.main-sub {
	flex-flow: column;
	width: auto;
	margin-top: 40px;
	display: flex;
}

.button-subscribe.main-sub-copy {
	flex-flow: column;
	width: auto;
	margin-top: 0;
	display: flex;
}

.button-subscribe-copy {
	width: 160px;
}

.footer_con {
	flex-flow: column;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
	display: flex;
}

.alert-close-btn {
	color: #fff;
	border-radius: 100px;
	transition: background-color 0.3s;
}

.alert-close-btn:hover {
	background-color: #282556;
}

.allertmessage {
	z-index: 5;
	justify-content: center;
	width: 100%;
	max-width: 1140px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	position: relative;
}

.image-17 {
	width: 20px;
	margin-left: 0;
	padding-left: 0;
}

.allert-close {
	grid-column-gap: 8px;
	justify-content: flex-end;
	align-items: center;
	display: flex;
	position: relative;
	inset: 0% auto;
}

.text-area {
	grid-column-gap: 16px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.info-banner-1-copy {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	background-color: var(--red-iowa);
	color: #fff;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 50px;
	padding: 5px 5%;
	display: flex;
	position: relative;
}

.alert-banner {
	grid-row-gap: 0px;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: auto;
	display: flex;
	position: relative;
}

.alertmain {
	z-index: 2000;
	background-color: #0e266b;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	min-height: 50px;
	display: flex;
	top: 0;
	overflow: hidden;
	box-shadow: 0 2px 5px #0003;
}

.text-alert {
	color: #fff;
	letter-spacing: -0.02em;
	margin-bottom: 0;
	margin-left: -8px;
	margin-right: 5px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
}

.icon-ani {
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	transition: color 0.2s;
	display: flex;
}

.image-18 {
	display: none;
}

.icon-wrapper-top {
	border-left: 1px #dad8d8;
	align-items: center;
	height: 100%;
	padding-left: 0;
	font-size: 18px;
	display: flex;
}

.div-block-185 {
	background-color: #cde8f680;
	align-items: flex-start;
	display: flex;
}

.promorow {
	background-color: #cde8f680;
	justify-content: center;
	align-items: flex-start;
	display: none;
}

.promorowwrapper {
	width: 1200px;
	display: flex;
}

.uui-button-7 {
	grid-column-gap: 0.5rem;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #0871b9;
	border: 0 solid #0871b9;
	border-radius: 0.5rem;
	justify-content: center;
	align-items: center;
	padding: 0.625rem 1.125rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
	box-shadow: 0 1px 2px #1018280d;
}

.uui-button-7:hover {
	background-color: var(--blue3);
	border-width: 0;
	border-color: #6941c6;
}

.uui-button-7:focus {
	background-color: #0871b9;
	border-width: 0;
	box-shadow: 0 1px 2px #1018280d, 0 0 0 4px #f4ebff;
}

.uui-form-button-wrapper {
	flex-direction: column;
	margin-top: 0.5rem;
	display: flex;
}

.uui-contact03_form-wrapper {
	flex-direction: column;
	align-items: flex-start;
	padding-top: 20px;
	display: flex;
}

.uui-contact03_form-wrapper.out {
	display: none;
}

.error-text {
	color: #e23939;
}

.uui-form-checkbox-label {
	color: var(--white);
	margin-bottom: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5;
}

.uui-form-field-wrapper {
	position: relative;
}

.uui-form_input-2 {
	color: #101828;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	border-radius: 0.5rem;
	height: auto;
	min-height: 2.75rem;
	margin-bottom: 0;
	padding: 0.5rem 0.875rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	transition: all 0.3s;
	box-shadow: 0 1px 2px #1018280d;
}

.uui-form_input-2:focus {
	color: #101828;
	border-color: #d6bbfb;
	box-shadow: 0 1px 2px #1018280d, 0 0 0 4px #f4ebff;
}

.uui-form_input-2::placeholder {
	color: #667085;
}

.uui-form_input-2.text-area {
	height: auto;
	min-height: 6.1rem;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	overflow: auto;
}

.uui-contact03_form {
	grid-column-gap: 1.5rem;
	grid-row-gap: 1.5rem;
	text-align: left;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	width: 100%;
	display: grid;
}

.uui-field-label {
	color: var(--white);
	margin-bottom: 0.5rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1.5;
}

.uui-form-checkbox {
	align-items: center;
	margin-bottom: 0;
	padding-left: 1.25rem;
	display: flex;
}

.error-message-2 {
	margin-top: 1.5rem;
	padding: 0.875rem 1rem;
}

.success-message-2 {
	background-color: #f4f4f4;
	padding: 1.5rem;
}

.success-text {
	font-weight: 600;
}

.uui-form-checkbox-icon {
	cursor: pointer;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	border-radius: 0.375rem;
	width: 1.25rem;
	min-width: 1.25rem;
	height: 1.25rem;
	min-height: 1.25rem;
	margin-top: 0;
	margin-left: -1.25rem;
	margin-right: 0.75rem;
	transition: all 0.3s;
}

.uui-form-checkbox-icon:hover {
	background-color: #f9f5ff;
	border-color: #0871b9;
}

.uui-form-checkbox-icon.w--redirected-checked {
	box-shadow: none;
	background-color: #f9f5ff;
	background-image: url("/20181016082423/assets/images/check.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 0.875rem 0.875rem;
	background-attachment: scroll;
	border-width: 1px;
	border-color: #0871b9;
	border-radius: 0.25rem;
}

.uui-form-checkbox-icon.w--redirected-focus {
	background-color: #f9f5ff;
	border-color: #0871b9;
	border-radius: 0.25rem;
	box-shadow: 0 0 0 4px #f4ebff;
}

.uui-text-style-link-2 {
	color: var(--white);
	text-decoration: underline;
	transition: color 0.3s;
}

.uui-text-style-link-2:hover {
	color: #344054;
}

.link-list-title-copy {
	color: #fff;
	text-align: center;
	text-transform: capitalize;
	text-align: center;
	text-transform: capitalize;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 0;
	font-family: Merriweather, serif;
	font-size: 16px;
	font-weight: 700;
	display: flex;
}

.image-19 {
	margin-bottom: 10px;
}

.link-3 {
	color: var(--white);
	text-decoration: none;
}

.link-3:hover {
	text-decoration: underline;
}

.btn-txt-footerbtn {
	color: var(--white);
	letter-spacing: 0;
	text-transform: uppercase;
	font-family: Merriweather, serif;
	font-size: 14px;
	font-weight: 400;
	position: relative;
}

.btn-bg-footerbtn {
	background-color: #5b96fe;
	border-radius: 100%;
	width: 120%;
	min-width: 5rem;
	height: 175%;
	display: block;
	position: absolute;
	inset: auto;
}

.btn-bg-footerbtn.c-10 {
	background-color: var(--red2);
	border-radius: 50rem;
	align-self: center;
	width: 130%;
	min-width: auto;
	height: 120%;
	inset: auto;
}

.btn-bg-footerbtn.c-10.main-sub {
	margin-top: 60px;
}

.btn-bg-footerbtn.footerbtn {
	background-color: var(--red2);
	border-radius: 50rem;
	align-self: center;
	width: 130%;
	min-width: auto;
	height: 120%;
	inset: auto;
}

.btn-bg-footerbtn.footerbtn.main-sub {
	margin-top: 60px;
}

.cardtext {
	background-color: #fff;
	margin-top: -32px;
	margin-left: 32px;
	padding: 48px 16px 16px 32px;
}

.pop-up-modal {
	z-index: 999999;
	background-color: #2a2a2dbf;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: none;
	position: fixed;
	inset: 0%;
}

.pop-up {
	text-align: center;
	background-color: #fff;
	flex-direction: column;
	align-items: center;
	width: 450px;
	height: auto;
	padding: 32px 30px;
	display: flex;
	position: relative;
	box-shadow: 1px 1px 10px 5px #00000040;
}

.pop-up-icon {
	background-color: #f6b685;
	background-image: none;
	border: 5px solid #fff;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	margin-left: auto;
	margin-right: auto;
	padding: 30px;
	position: absolute;
	inset: -13% 0% auto;
}

.exit-button {
	background-color: var(--blue2);
	cursor: pointer;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	padding: 10px;
	position: absolute;
	inset: 16px 16px auto auto;
}

.pop-up-heading {
	color: var(--blue);
	letter-spacing: 2px;
	text-transform: capitalize;
	max-width: 250px;
	font-family: Merriweather, serif;
	font-size: 41px;
	font-weight: 700;
	line-height: 1.1em;
}

.primary-button {
	letter-spacing: 2px;
	text-transform: uppercase;
	background-color: #e8832e;
	border-radius: 50px;
	margin-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	font-size: 15px;
	font-weight: 700;
}

.primary-button:hover {
	background-color: #f6b685;
}

.paragraph {
	max-width: 350px;
}

.card-paragraph {
	font-size: 16px;
	line-height: 24px;
}

.flipcard-wrapper {
	cursor: pointer;
	width: 23%;
	height: auto;
	position: relative;
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
	transform-style: preserve-3d;
	transition: transform 450ms ease;
}

.flipcard-wrapper:hover {
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(180deg) rotateZ(0deg) skew(0deg);
}

.spotlight-container {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	flex-flow: wrap;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	display: flex;
}

.large-card-header-2 {
	color: #1f1f1f;
	letter-spacing: 1px;
	text-transform: capitalize;
	background-color: #fcfcfc;
	margin-bottom: 0.6rem;
	padding: 0.2rem 0.7rem;
	font-family: Pacifico, sans-serif;
	font-size: 1.5rem;
	font-weight: 400;
	box-shadow: 2px 2px 18px -9px #00000080;
}

.card-subtext-2 {
	color: #1f1f1f;
	text-align: left;
	letter-spacing: 1px;
	text-transform: uppercase;
	cursor: pointer;
	background-color: #fcfcfc;
	margin-bottom: 1rem;
	padding: 0.2rem 0.6rem;
	font-size: 0.8rem;
	font-weight: 500;
	line-height: 1.7;
	transition: all 0.35s;
	display: none;
	box-shadow: 2px 2px 18px #0000004d;
}

.card-subtext-2:hover {
	color: #ececec;
	background-color: #a02226;
}

.flipcard-back {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	background-color: #fff;
	flex-direction: column;
	align-items: flex-start;
	min-width: 245px;
	height: 375px;
	padding: 27px;
	display: flex;
	position: absolute;
	inset: 0%;
	transform: rotateX(0) rotateY(180deg) rotateZ(0);
	box-shadow: 7px 7px 40px 6px #00000017;
}

.flipcard-front {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	color: #fff;
	background-position: 50%;
	background-size: cover;
	min-width: 245px;
	height: 375px;
	padding-top: 72px;
	padding-left: 40px;
	padding-right: 51px;
	position: relative;
	box-shadow: 7px 7px 40px 6px #00000017;
}

.div-block-186 {
	background-color: #e5f3fa;
}

.welcomecon {
	background-color: #e5f3fa;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding-top: 40px;
	display: flex;
}

.welcomewrapper {
	max-width: 1280px;
}

.hero_sub-heading-copy {
	color: #005c91;
	text-align: center;
	letter-spacing: 0;
	text-transform: capitalize;
	background-color: #0000;
	max-width: 1280px;
	margin-top: 24px;
	font-family: Poppins, sans-serif;
	font-size: 4vw;
	font-weight: 200;
	line-height: 1;
	position: relative;
}

.hero_info-text-copy {
	color: #242424;
	text-align: center;
	letter-spacing: 1px;
	max-width: 900px;
	margin-bottom: 0;
	padding-top: 20px;
	font-family: Poppins, sans-serif;
	font-size: 16px;
}

.responsiveonly {
	display: none;
}

.button-4 {
	color: #166b3e;
	text-transform: uppercase;
	background-color: #0000;
	padding: 0;
	font-weight: 600;
	transition: all 0.45s;
}

.button-4:hover {
	color: #0a0442;
	-webkit-text-stroke-color: #160042;
	padding-right: 0;
}

.padding-section-medium {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.text-block-3 {
	color: #374957;
}

.padding-global {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.div-block-7 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.div-block-7:hover {
	width: auto;
	height: auto;
}

.section_features {
	background-color: #edf7ff;
	display: none;
}

.features_grid {
	grid-column-gap: 2.5rem;
	grid-row-gap: 2rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.features_header {
	grid-column-gap: 3rem;
	grid-row-gap: 4rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.spotlightscontainer {
	border-radius: 5px;
	height: 55%;
	overflow: hidden;
}

.grey-arrow-3 {
	width: 0;
	margin-left: 10px;
}

.div-block-6 {
	align-items: center;
	width: auto;
	height: 45px;
	margin-bottom: 0;
	display: flex;
	overflow: hidden;
}

.spotlightswrapper {
	background-color: #07693a1a;
	border-radius: 10px;
	flex-direction: column;
	align-items: flex-start;
	width: 30%;
	height: auto;
	padding: 15px;
	display: flex;
}

.features_item {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	background-color: #fff;
	border-radius: 1.25rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-content: start;
	align-items: start;
	padding: 2rem;
	display: grid;
}

.div-block-12 {
	justify-content: space-between;
	width: 100%;
	display: flex;
}

.icon-small {
	height: 2rem;
}

.container-large {
	width: 100%;
	max-width: 80rem;
	margin-left: auto;
	margin-right: auto;
}

.image-21 {
	border-radius: 5px;
	width: 120%;
	max-width: none;
}

.image-21.img-zoom {
	transition: all 0.45s;
}

.image-21.img-zoom:hover {
	transform: scale(1.1);
}

.features_components {
	grid-column-gap: 4rem;
	grid-row-gap: 4rem;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.arrow-2 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.arrow-2:hover {
	width: auto;
	height: auto;
}

.news-section {
	color: #374957;
	background-color: #0000;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 60px 10%;
	display: flex;
}

.heading-2 {
	color: #0a0442;
	text-transform: uppercase;
	margin-top: 10px;
	font-size: 17px;
	font-weight: 600;
	line-height: 35px;
}

.spotlightsnodesktop {
	justify-content: space-between;
	width: 100%;
	display: flex;
}

.spotlight-container-copy {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	flex-flow: wrap;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	display: flex;
}

.spotlight-container-nodesktop {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	flex-flow: wrap;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	display: none;
}

.footer_linksy {
	border-top: 0px solid var(--blue4);
	color: var(--white);
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-top: 16px;
	margin-left: 20px;
	padding-top: 0;
	font-family: Poppins, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	position: relative;
	text-align: center;
}

.link-4 {
	color: var(--white);
}

.link-4:hover {
	text-decoration: none;
}

.promo-art-blue-divider {
	background-color: #fff;
	border: 1px solid #427d0e;
	border-radius: 0;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 30px;
	max-width: none;
	height: 15px;
	padding: 1px;
	display: flex;
}

.heading-50 {
	color: #0e266b;
}

.button-6 {
	color: #2e2b2b;
	text-align: center;
	text-transform: capitalize;
	background-color: #f89c3e;
	border-radius: 2px;
	width: auto;
	min-width: auto;
	height: 44px;
	margin-top: 1rem;
	margin-bottom: 0;
	margin-right: 1.5rem;
	padding: 9px 40px;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-decoration: none;
	display: inline-block;
}

.button-6:hover {
	color: #fff;
	background-color: #0e266b;
	background-image: none;
}

.button-6:active {
	background-image: none;
	box-shadow: inset 0 3px 10px #0003;
}

.button-6.buttonstyle {
	color: #0e266b;
	border: 1px solid #f89c3e;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 700;
}

.button-6.buttonstyle:hover {
	color: #fff;
	border-width: 1px;
	border-color: #fff;
}

.right-col-item-wrap {
	border-top: 3px #d5a021;
	margin-top: 0;
	margin-left: 0;
	position: relative;
}

.breadcrumb-divider-2 {
	color: #213a7f;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	font-size: 1rem;
	display: inline-block;
}

.paragraph-26 {
	opacity: 1;
	font-size: 16px;
	line-height: 22px;
	display: none;
}

.child-wrap {
	background-color: var(--blue);
	border-bottom: 1px #000;
	padding-bottom: 0;
	padding-left: 0;
}

.child-wrap._1st {
	display: none;
}

.unordered-list {
	margin-bottom: 1rem;
	list-style-type: disc;
}

.inside-page-container {
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	max-width: 1300px;
	display: flex;
}

.inside-page-container.white-background {
	background-color: #0000;
	flex-flow: row;
	justify-content: flex-start;
	align-items: stretch;
	width: 100%;
	max-width: none;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	display: flex;
	position: relative;
}

.heading-1inside {
	color: #0e266b;
	font-size: 46px;
	font-weight: 800;
}

.heading-1inside.hide {
	display: none;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.error {
	background-color: #fd363626;
	border-left: 6px solid #c70616;
	font-size: 1.1rem;
}

.screen-message.success {
	background-color: #11b91e40;
	border-left: 6px solid #11b91e;
	font-size: 1.1rem;
}

.primary-button-con {
	justify-content: center;
	align-items: center;
	display: flex;
}

.heading-49 {
	color: #0e266b;
}

.heading-45 {
	color: #0e266b;
	font-size: 38px;
	font-weight: 800;
}

.breadcrumbs-list-item-2 {
	float: left;
	color: #333;
	display: inline-block;
}

.tertiary-button-con {
	cursor: pointer;
	background-color: #427d0e00;
	border-radius: 0;
	justify-content: flex-end;
	align-items: center;
	width: auto;
	margin-top: 0;
	padding: 15px 0;
	display: flex;
}

.tertiary-button-con.video-only {
	justify-content: center;
	padding-top: 0;
}

.tertiary-button-con.video-only._3rd {
	justify-content: flex-start;
	padding-bottom: 0;
}

.paragraph-22 {
	color: #333;
	font-size: 16px;
	font-weight: 300;
	line-height: 23px;
}

.heading-1 {
	color: var(--blue);
	text-transform: capitalize;
	margin-top: 0;
	font-family: Merriweather, serif;
	font-size: 45px;
	font-weight: 700;
	line-height: 55px;
}

.paragraph-23 {
	font-weight: 300;
}

.heading-46 {
	color: #0e266b;
	font-weight: 800;
}

.breadcrumb-link-2 {
	opacity: 0.69;
	color: #333;
	cursor: pointer;
	border-bottom: 1px #196ab5;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: underline;
	transition: all 0.45s;
}

.breadcrumb-link-2:hover {
	opacity: 1;
	color: #0e266b;
	border-bottom-color: #0000;
	text-decoration: none;
}

.breadcrumb-link-2.current-bc-link {
	opacity: 1;
	color: #333;
	border-bottom-style: none;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumb-link-2.current-bc-link:hover {
	color: #196b19;
}

.left-nav-wrapper-2 {
	background-color: #335962;
	background-image: linear-gradient(#fff, #fff);
	border-top: 0 solid #335962;
	border-bottom: 1px #8ab0dd26;
	border-left: 1px #8ab0dd26;
	border-right: 0 #8ab0dd26;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	width: 30%;
	height: auto;
	margin-bottom: 0;
	margin-right: 0;
	padding: 0 10px 40px;
	overflow: hidden;
	min-width: 320px;
}

.block-quote-2 {
	border: 0px solid var(--blue);
	color: #333;
	background-color: #e4f3fa00;
	border-radius: 8px;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1.25rem;
	font-style: italic;
	font-weight: 300;
	line-height: 2.2rem;
}

.heading-53 {
	color: #0e266b;
}

.left-nav-section-title {
	text-transform: none;
	border-bottom: 1px #00000026;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 400;
	line-height: 50px;
}

.element-intro-header-wrap {
	border-left: 5px solid var(--blue);
	background-color: var(--button-white);
	color: #fff;
	width: auto;
	max-width: none;
	margin-top: 30px;
	margin-left: 20px;
	padding: 15px 40px;
}

.promo-art-blue-divider-inside {
	background-color: #427d0e;
	border: 0 solid #fff;
	border-radius: 0;
	justify-content: center;
	align-self: center;
	align-items: center;
	width: 28px;
	max-width: none;
	height: 10px;
	font-size: 1px;
	line-height: 1px;
	display: flex;
}

.ivider-left {
	background-color: #427d0e;
	width: 50%;
	height: 2px;
	margin-right: 5px;
}

.secondary-button-con {
	justify-content: center;
	align-items: center;
	display: flex;
}

.buttons-wrap {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	background-color: #98abe400;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	place-items: center start;
	width: 90%;
	margin-bottom: 40px;
	padding-top: 20px;
	padding-bottom: 20px;
	display: grid;
}

.link_blue {
	color: #233d81;
	letter-spacing: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	font-family: Merriweather, serif;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
	display: inline-block;
}

.link_blue:hover {
	text-decoration: underline;
}

.h5-header-link-2 {
	color: var(--blue);
	text-transform: uppercase;
	border-bottom: 0 solid #3333;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-decoration: underline;
	transition: all 0.2s;
	display: inline-block;
}

.h5-header-link-2:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-color: #0000;
	text-decoration: none;
}

.inside-page-header-section-2 {
	background-color: #b08d57;
	background-position: 0 0, 0 0, 100%;
	background-repeat: repeat, repeat, no-repeat;
	background-size: auto, auto, cover;
	height: 450px;
	display: none;
	position: relative;
}

.ordered-list {
	margin-bottom: 1rem;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.horiz-divider-3 {
	color: #197974;
	background-color: #3330;
	justify-content: center;
	align-items: center;
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: none;
}

.h4-header-link-2 {
	color: var(--blue);
	border-bottom: 0 solid #3333;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: underline;
	transition: all 0.2s;
	display: inline-block;
}

.h4-header-link-2:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-width: 0;
	border-bottom-color: #0000;
	text-decoration: none;
}

.heading-52 {
	color: #0e266b;
}

.left-nav-section-title-link-2,
.left-nav-section-title-link-2:hover {
	background-color: var(--blue5);
	color: var(--blue);
	border: 1px #3333;
	border-top: 0 solid #19797433;
	border-bottom: 1px solid #19797433;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding: 0.5rem 0 0.5rem 0.5rem;
	font-family: Merriweather, serif;
	font-size: 26px;
	font-weight: 700;
	line-height: 2.4rem;
	text-decoration: none;
	display: block;
}

.paragraph-9 {
	color: #1a1a1a;
}

.paragraph-9.black {
	color: #333;
	margin-bottom: 0;
	font-size: 1rem;
	line-height: 26px;
}

.div-block-170 {
	z-index: 999;
	background-color: #fff;
	border-top: 0 solid #335962;
	margin-bottom: 2rem;
	padding: 0 20px 10px;
	position: relative;
	top: 0;
	bottom: auto;
}

.heading-14 {
	color: #1a1a1a;
	margin-top: 10px;
}

.heading-14.black {
	color: #0e266b;
	margin-top: 0;
	font-size: 1.2rem;
	font-weight: 800;
	line-height: 26px;
}

.breadcrumbs-wrapper {
	color: #424242;
	max-width: 1500px;
	margin-bottom: 30px;
	padding-left: 0;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	display: block;
}

.breadcrumbs-wrapper.old-breadcrump {
	background-color: #fff;
	border-top: 0 solid #0e266b;
	border-left: 0 solid #6677a54d;
	border-right: 0 solid #6677a54d;
	max-width: 1200px;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 40px;
	padding-bottom: 20px;
	display: block;
}

.breadcrumbs-wrapper.old-breadcrump.notop {
	width: 100%;
	max-width: none;
	margin-top: 0;
	padding-top: 20px;
}

.breadcrumbs-wrapper.out {
	display: none;
}

.heading-51 {
	color: #0e266b;
}

.secondarystyle {
	color: #0e266b;
	letter-spacing: 1px;
	background-color: #0000;
	border: 1px solid #0e266b;
	margin-top: 0;
	font-size: 16px;
	font-weight: 600;
	transition: all 0.35s;
	display: none;
}

.secondarystyle:hover {
	color: #fff;
	background-color: #0e266b;
}

.intro-paragraph-2 {
	color: #333;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	font-size: 1.25rem;
	font-weight: 300;
	line-height: 1.75rem;
}

.h6-header-link-2 {
	color: var(--blue);
	text-transform: uppercase;
	border-bottom: 0 solid #3333;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;
	text-decoration: underline;
	transition: all 0.2s;
	display: inline-block;
}

.h6-header-link-2:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-color: #0000;
	text-decoration: none;
}

.left-nav-link-5 {
	color: #fff;
	text-transform: none;
	border: 1px #3333;
	border-top-color: #000;
	border-bottom: 1px solid #ffffff4d;
	border-bottom-left-radius: 0;
	width: 100%;
	padding: 10px 0.5rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
	text-decoration: none;
	transition: all 0.4s;
	display: block;
}

.left-nav-link-5:hover {
	border-left: 4px solid var(--red2);
	color: #fff;
	padding-left: 0.5rem;
	padding-right: 0;
	text-decoration: underline;
}

.left-nav-link-5.w--current:hover {
	transform: none;
}

.left-nav-link-5.greatgrandchild-link {
	text-transform: none;
	border: 1px #3333;
	border-top-color: #000;
	border-bottom: 1px solid #fff3;
	width: auto;
	padding: 10px 0.5rem 10px 1.5rem;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.13rem;
	transition: all 0.4s;
}

.left-nav-link-5.greatgrandchild-link:hover {
	border-left: 4px solid var(--red2);
	color: #fff;
}

.left-nav-link-5.grandchild-link {
	color: #fff;
	text-transform: none;
	border-top-color: #0003;
	border-bottom-color: #fff3;
	border-left-style: none;
	border-left-color: #3333;
	border-right-style: none;
	padding: 10px 0 10px 1rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.25rem;
	transition: all 0.4s;
}

.left-nav-link-5.grandchild-link:hover {
	color: #fff;
	border-left-color: #8ab0dd80;
}

.text-link-10 {
	color: #333;
	border: 0 #0000;
	border-bottom: 0 solid #3333;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem;
	text-decoration: underline;
	transition: all 0.2s;
}

.text-link-10:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-style: none;
	border-bottom-color: #0000;
	text-decoration: none;
}

.horiz-divider-2 {
	background-color: #427d0e66;
	background-image: linear-gradient(-90deg, var(--blue), var(--blue4));
	color: #197974;
	height: 2px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	line-height: 20px;
}

.inside-page-content-left-right {
	z-index: 0;
	box-shadow: none;
	color: #000;
	background-color: #cde8f64d;
	justify-content: center;
	margin-top: 0;
	margin-left: 0%;
	margin-right: 0%;
	padding: 0;
	font-size: 1rem;
	min-height: 500px;
	position: relative;
}

.link_arrow_image {
	color: #0b2239;
	width: 17px;
	height: 17px;
	margin-left: 0;
	padding-left: 8px;
	font-weight: 700;
	text-decoration: none;
	display: inline-block;
}

.h3-header-link-2 {
	color: var(--blue);
	border-bottom: 0 solid #3333;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 30px;
	text-decoration: underline;
	transition: all 0.2s;
	display: inline-block;
}

.h3-header-link-2:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-color: #0000;
	text-decoration: none;
}

.imgblock {
	width: 100%;
	min-height: 250px;
	margin-top: 0;
	margin-bottom: 20px;
	padding-top: 0;
	display: flex;
}

.breadcrumbs-list-2 {
	color: #196ab5;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 20px;
	font-family: Open Sans, sans-serif;
}

.table-div {
	font-weight: 300;
}

.header-link {
	color: #fff;
	border: 1px #000;
	text-decoration: underline;
}

.header-link:hover {
	color: #fff;
	border-bottom-color: #0000;
	text-decoration: none;
	transform: translate(46px);
}

.divider-right {
	background-color: #427d0e;
	width: 50%;
	height: 2px;
	margin-left: 5px;
}

.element-category-section {
	background-image: none;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 0;
	padding-top: 0;
	padding-bottom: 0;
	display: flex;
}

.paragraph-25 {
	color: #333;
	margin-bottom: 1rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
}

.paragraph-25.list-item {
	margin-bottom: 0;
}

.screen-message-text {
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.2rem;
}

.heading-47 {
	color: #0e266b;
}

.left-side-col {
	float: left;
	background-color: #fef8e0;
	flex: none;
	width: 25%;
	height: 100.002%;
	margin-left: 2%;
	padding: 20px 50px 20px 25px;
	position: relative;
	right: 0;
}

.left-side-col.show {
	background-color: var(--blue5);
	color: #000;
	border-radius: 5px;
	width: 100%;
	height: auto;
	margin-top: 20px;
	margin-left: 0%;
	margin-right: 0;
	padding-left: 20px;
	padding-right: 20px;
	right: auto;
}

.heading-48 {
	color: #0e266b;
}

.inside-hero-section-2 {
	flex-flow: column;
	align-items: center;
	max-width: 1200px;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.content-container {
	background-color: #fff;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	width: 70%;
	min-width: 700px;
	max-width: none;
	margin-bottom: 2rem;
	margin-left: 0;
	margin-right: 0;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	display: block;
}

.content-container.threequarterwidth {
	border-left: 1px solid #6677a54d;
	border-right: 1px solid #6677a54d;
	width: 80%;
	height: auto;
	margin-top: 0;
	margin-bottom: 2rem;
	padding-bottom: 0;
	position: relative;
	top: 0;
}

.content-container.threequarterwidth.fullpage-size {
	border-bottom: 0 solid #6677a5;
	border-left-width: 0;
	border-right-width: 0;
	width: 100%;
	min-width: auto;
	max-width: none;
	margin-bottom: 0;
	padding-bottom: 4rem;
}

.h2-header-link-2 {
	color: var(--blue);
	text-align: left;
	border-bottom: 0 solid #3333;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	font-size: 32px;
	font-weight: 700;
	line-height: 36px;
	text-decoration: underline;
	transition: all 0.2s;
	display: inline-block;
}

.h2-header-link-2:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-color: #0000;
	text-decoration: none;
}

.heading-60 {
	color: var(--blue);
	font-family: Merriweather, serif;
}

.sidebar-section-wrap {
	border-top: 1px #00000026;
	margin-bottom: 2.441em;
	margin-left: 0;
	font-size: 0.875rem;
	line-height: 1.5em;
	position: relative;
}

.heading-61 {
	color: var(--blue);
	font-family: Merriweather, serif;
}

.heading-62 {
	color: var(--blue);
	font-family: Merriweather, serif;
	font-size: 32px;
	font-weight: 700;
}

.paragraph-27 {
	color: #333;
	font-size: 16px;
	font-weight: 300;
	line-height: 23px;
}

.paragraph-28 {
	font-weight: 300;
}

.heading-63 {
	color: var(--blue);
	font-family: Merriweather, serif;
	font-weight: 700;
}

.right-nav-2 {
	background-color: #fff;
	border-top: 0 solid #335962;
	width: 25%;
	max-width: none;
	margin-bottom: 0;
	margin-left: 0;
	padding: 0 20px 40px;
	font-style: normal;
}

.heading-64 {
	color: var(--blue);
	font-family: Merriweather, serif;
}

.horiz-divider-4 {
	color: #197974;
	background-color: #3330;
	justify-content: center;
	align-items: center;
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: none;
}

.heading-65 {
	color: var(--blue);
	font-family: Merriweather, serif;
}

.paragraph-29 {
	color: #1a1a1a;
}

.paragraph-29.black {
	color: #333;
	margin-bottom: 0;
	font-size: 1rem;
	line-height: 26px;
}

.div-block-188 {
	z-index: 999;
	background-color: #fff;
	border-top: 0 solid #335962;
	margin-bottom: 0;
	padding: 0 20px 10px;
	position: relative;
	top: 0;
	bottom: auto;
}

.heading-66 {
	color: #1a1a1a;
	margin-top: 10px;
}

.heading-66.black {
	color: var(--blue);
	margin-top: 0;
	font-family: Merriweather, serif;
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 26px;
}

.heading-67 {
	color: var(--blue);
	font-family: Merriweather, serif;
}

.sidebar-section-title-2 {
	color: var(--blue);
	margin-top: 0;
	margin-bottom: 0.75em;
	font-family: Merriweather, serif;
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 700;
}

.link-8 {
	color: #333;
	border: 1px solid #0000;
	border-bottom-style: none;
	font-size: 1rem;
	text-decoration: underline;
}

.link-8:hover {
	color: #0e266b;
	border-bottom: 0 #9ccb3c00;
	text-decoration: none;
}

.link-8.w--current {
	color: #28313b;
	font-weight: 700;
}

.sidebar-list-item {
	color: #213a7f;
	margin-bottom: 0.75em;
	padding-left: 0;
}

.sidebar-list-item:hover {
	color: #4a73bc;
}

.sidebar-list {
	padding-left: 0;
}

.paragraph-30 {
	color: #333;
	margin-bottom: 1rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
}

.paragraph-30.list-item {
	margin-bottom: 0;
}

.heading-68,
.heading-69 {
	color: var(--blue);
	font-family: Merriweather, serif;
}

.body-2 {
	background-color: #cde8f64d;
	font-family: Poppins, sans-serif;
	font-size: 16px;
}

.image-22 {
	width: 100%;
	max-width: none;
}

.content-container-full {
	background-color: #fff;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	width: 70%;
	min-width: 700px;
	max-width: none;
	margin-bottom: 2rem;
	margin-left: 0;
	margin-right: 0;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	display: block;
}

.content-container-full.threequarterwidth {
	border-left: 1px solid #6677a54d;
	border-right: 1px solid #6677a54d;
	width: 80%;
	height: auto;
	margin-top: 0;
	margin-bottom: 2rem;
	padding-bottom: 0;
	position: relative;
	top: 0;
}

.content-container-full.threequarterwidth.fullpage-size {
	border-bottom: 0 solid #6677a5;
	border-left-width: 0;
	border-right-width: 0;
	width: 100%;
	min-width: auto;
	max-width: 1200px;
	margin-bottom: 0;
	padding-bottom: 1rem;
}

.breadcrumbs-wrapper-full {
	color: #424242;
	max-width: 1500px;
	margin-bottom: 30px;
	padding-left: 0;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	display: block;
}

.breadcrumbs-wrapper-full.old-breadcrump {
	background-color: #fff;
	border-top: 0 solid #0e266b;
	border-left: 0 solid #6677a54d;
	border-right: 0 solid #6677a54d;
	max-width: 1200px;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 40px;
	padding-bottom: 20px;
	display: block;
}

.breadcrumbs-wrapper-full.old-breadcrump.notop {
	width: 65%;
	max-width: 900px;
	margin-top: 0;
}

.breadcrumbs-wrapper-full.old-breadcrump.notop.fullpage {
	padding-top: 20px;
}

.breadcrumbs-wrapper-full.out {
	display: none;
}

.inside-page-container-full {
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	max-width: 1300px;
	display: flex;
}

.inside-page-container-full.white-background {
	background-color: #0000;
	flex-flow: row;
	justify-content: flex-start;
	align-items: stretch;
	width: 65%;
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	display: flex;
	position: relative;
}

.element-intro-header-wrap-full {
	border-left: 5px solid var(--blue);
	color: #fff;
	width: auto;
	max-width: none;
	margin-top: 30px;
	margin-left: 20px;
	padding: 15px 40px;
}

.element-category-section-full {
	background-color: #fff;
	background-image: none;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 88%;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	padding-top: 60px;
	padding-bottom: 0;
	display: flex;
}

.div-block-189 {
	background-color: var(--button-white);
	width: 100%;
	max-width: 1200px;
}

.link_blue-copy {
	color: #233d81;
	letter-spacing: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	font-family: Merriweather, serif;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
	display: none;
}

.link_blue-copy:hover {
	text-decoration: underline;
}

.btn-bg2 {
	background-color: #5b96fe;
	border-radius: 100%;
	width: 120%;
	min-width: 5rem;
	height: 175%;
	display: block;
	position: absolute;
	inset: auto;
}

.btn-bg2.c-10 {
	background-color: var(--red2);
	border-radius: 50rem;
	align-self: center;
	width: 130%;
	min-width: auto;
	height: 120%;
	inset: auto;
}

.btn-bg2.c-10.main-sub {
	margin-top: 60px;
}

.btn-bg2.footerbtn {
	background-color: var(--red2);
	border-radius: 50rem;
	align-self: center;
	width: 130%;
	min-width: auto;
	height: 120%;
	inset: auto;
}

.btn-bg2.footerbtn.main-sub {
	margin-top: 60px;
}

.btn-bg2.c-10-copy {
	background-color: var(--blue);
	border-radius: 50rem;
	align-self: center;
	width: 130%;
	min-width: auto;
	height: 120%;
	inset: auto;
}

.btn-bg2.c-10-copy.main-sub {
	margin-top: 60px;
}

.element-category-section-full-copy {
	background-color: #fff0;
	background-image: none;
	flex-direction: column;
	align-items: center;
	width: auto;
	max-width: none;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	padding-top: 29px;
	padding-bottom: 0;
	display: flex;
}

.div-block-190 {
	background-color: var(--button-white);
	width: auto;
	max-width: 1200px;
}

.title-full {
	background-color: var(--button-white);
	width: 65%;
	max-width: 900px;
}

.div-block-191 {
	background-image: url("/20181016082423/assets/images/lines-1-01.svg");
	background-position: 0 0;
	background-repeat: repeat-x;
	background-size: auto;
	height: 10px;
	margin-left: 60px;
	padding-left: 0;
}

.bottonquoteline {
	background-image: url("/20181016082423/assets/images/lines-2b.svg");
	background-position: 0 0;
	background-repeat: repeat-x;
	background-size: auto;
	height: 10px;
	margin-left: 0;
	margin-right: 80px;
	padding-left: 0;
}

.topquoteline {
	background-image: url("/20181016082423/assets/images/lines-1-01b.svg");
	background-position: 0 0;
	background-repeat: repeat-x;
	background-size: auto;
	height: 10px;
	margin-left: 80px;
	padding-left: 0;
}

.icon-wrapper-top-tablet {
	border-left: 1px #dad8d8;
	align-items: center;
	height: 100%;
	padding-left: 0;
	font-size: 18px;
	display: none;
}

.nav-link-copy {
	color: #fff;
	letter-spacing: 0;
	text-transform: capitalize;
	justify-content: center;
	align-items: flex-end;
	width: auto;
	margin-left: 0;
	margin-right: 0;
	padding: 1.3rem 2rem;
	font-family: Playfair Display, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	text-decoration: none;
	transition: background-color 0.3s;
	display: flex;
	position: static;
}

.nav-link-copy:hover {
	color: #fff;
	background-color: #9daad400;
	border-bottom: 3px #3e85ed;
	width: auto;
}

.nav-link-copy.last {
	padding-right: 2rem;
}

.nav-link-copy.first {
	text-transform: none;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
	font-weight: 400;
	transition-property: background-color;
	transition-duration: 0.3s;
}

.nav-link-copy.first:hover {
	background-color: #9daad400;
	text-decoration: underline;
}

.nav-link-copy.first.upper {
	padding-top: 0;
	padding-bottom: 0;
	font-family: Merriweather, serif;
	font-size: 18px;
	font-weight: 400;
}

.nav-link-copy.first.upper-copy {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 18px;
}

.nav-link-copy.first.out {
	display: none;
}

.nav-link-copy.first.upper-top {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 14px;
}

.nav-link-copy.first.upper-top-copy {
	background-color: #d1252a;
	border-radius: 10px;
	padding: 0.75rem 1rem;
	font-family: Merriweather, serif;
	font-size: 14px;
	display: none;
}

.nav-link-copy.first-copy {
	text-transform: none;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
	font-weight: 400;
	transition-property: background-color;
	transition-duration: 0.3s;
	display: none;
}

.nav-link-copy.first-copy {
	text-transform: none;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
	font-weight: 400;
	transition-property: background-color;
	transition-duration: 0.3s;
}

.nav-link-copy.first-copy:hover {
	background-color: #9daad400;
	text-decoration: underline;
}

.nav-link-copy.first-copy.upper {
	padding-top: 0;
	padding-bottom: 0;
	font-family: Merriweather, serif;
	font-size: 18px;
	font-weight: 400;
}

.nav-link-copy.first-copy.upper-copy {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 18px;
}

.nav-link-copy.first-copy.out {
	display: none;
}

.nav-link-copy.first-copy.upper-top {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.25rem;
	font-family: Merriweather, serif;
	font-size: 14px;
}

.nav-link-copy.first-copy.upper-top-copy {
	background-color: #d1252a;
	border-radius: 10px;
	padding: 0.75rem 1rem;
	font-family: Merriweather, serif;
	font-size: 14px;
	display: none;
}

.subscribebtnmenu {
	display: none;
}

.book_description-title-mobile {
	color: var(--blue);
	letter-spacing: -1px;
	margin-bottom: 0;
	padding-left: 0;
	font-family: Merriweather, serif;
	font-weight: 700;
	display: none;
}

.book_description-year-mobile {
	color: var(--red2);
	margin-top: 8px;
	margin-bottom: 40px;
	margin-left: 0;
	padding-left: 0;
	font-family: Poppins, sans-serif;
	font-size: 18px;
	font-weight: 500;
	display: none;
}

.book_description-year-mobile.is_dark-text {
	color: var(--black);
}

.slider-three2 {
	background-position: 0 0, 0 0;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
}

.footercol-follow {
	flex-flow: column;
	align-items: center;
	width: 33%;
	display: none;
}

.link-list-title-copy,
.link-list-title-2,
.link-list-title-3 {
	color: #fff;
	text-align: center;
	text-transform: capitalize;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	font-size: 16px;
	font-weight: 700;
	display: flex;
}

@media screen and (max-width: 991px) {
	.book_object {
		width: 220px;
		margin-top: 40px;
	}

	.book_object-wrapper {
		padding-top: 40px;
		padding-left: 10px;
	}

	.book_description-wrapper {
		padding-top: 20px;
	}

	.book_description-title {
		font-size: 26px;
	}

	.hero_container {
		max-width: none;
		height: 100%;
		top: -142px;
	}

	.section_hero {
		justify-content: center;
		height: 40vh;
	}

	.hero_title-wrapper {
		height: 100%;
		margin-bottom: 0;
	}

	.footer_sub-title {
		letter-spacing: 0;
	}

	.lottie-bg {
		height: 440px;
	}

	.header-mobile-search-reveal {
		flex-grow: 1;
		height: 3rem;
		min-height: 3rem;
		overflow: hidden;
	}

	.menu-nav-button {
		float: right;
		box-shadow: none;
		color: #fff;
		text-align: center;
		text-transform: uppercase;
		background-color: #0000;
		flex-shrink: 0;
		order: 1;
		justify-content: flex-start;
		height: auto;
		margin-top: 5px;
		padding: 0 0;
		font-family: Oswald, sans-serif;
		font-weight: 400;
		line-height: 4rem;
		transition: all 0.3s;
		display: block;
		top: auto;
	}

	.menu-nav-button:hover {
		color: #e9ece380;
	}

	.menu-nav-button.w--open {
		color: #fff;
		background-color: #0000;
	}

	.account-icon {
		width: 100px;
		transform: translate(0, -30px);
	}

	.menu-button-icon-2 {
		box-sizing: border-box;
		float: left;
		color: var(--white);
		align-self: center;
		font-size: 2.8rem;
		line-height: 2rem;
	}

	.header-contents-wrap {
		flex-flow: row;
		justify-content: space-between;
		height: 107px;
		margin-top: 0;
		padding-left: 0;
		padding-right: 2rem;
		display: flex;
	}

	.two-part-button-list {
		z-index: 150;
		background-color: #1d1e50;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}

	.secondary-nav-list-item {
		border-right: 1px #0009;
		flex-grow: 1;
		justify-content: center;
		align-self: stretch;
		padding: 0;
		display: flex;
	}

	.navbar {
		flex-flow: row;
		justify-content: space-between;
		align-items: stretch;
		width: auto;
		height: 110px;
		margin-top: -1px;
		display: block;
		width: 100%;
	}

	.nav-link {
		color: #1a2238;
		border-top: 1px #000;
		border-bottom: 1px solid #3333330d;
		justify-content: flex-start;
	}

	.nav-link:hover {
		color: #ff6a3d;
		background-color: #fafbfc;
		border-bottom: 1px solid #3333330d;
	}

	.nav-link.last {
		color: #1a2238;
	}

	.nav-link.last:hover {
		color: #ff6a3d;
		border-bottom-color: #3333330d;
	}

	.nav-link.first {
		background-color: var(--button-white);
		border-top-style: solid;
		border-top-color: #0000001a;
		border-bottom-color: #3333330d;
	}

	.nav-link.first:hover {
		background-color: var(--button-white);
		color: #000;
	}

	.nav-link.first.upper {
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first.upper:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link.first.upper-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first.upper-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link.first.upper-top {
		color: var(--white);
		background-color: #fff0;
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first.upper-top:hover {
		color: var(--button-white);
		background-color: #fafbfc00;
	}

	.nav-link.first.upper-top-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first.upper-top-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link.first.nodesktop {
		display: flex;
	}

	.nav-link.first-copy {
		background-color: var(--button-white);
		border-top-style: solid;
		border-top-color: #0000001a;
		border-bottom-color: #3333330d;
		display: none;
	}

	.nav-link.first-copy {
		background-color: var(--button-white);
		border-top-style: solid;
		border-top-color: #0000001a;
		border-bottom-color: #3333330d;
	}

	.nav-link.first-copy:hover {
		background-color: var(--button-white);
		color: #000;
	}

	.nav-link.first-copy.upper {
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first-copy.upper:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link.first-copy.upper-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first-copy.upper-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link.first-copy.upper-top {
		color: var(--white);
		background-color: #fff0;
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first-copy.upper-top:hover {
		color: var(--button-white);
		background-color: #fafbfc00;
	}

	.nav-link.first-copy.upper-top-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first-copy.upper-top-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link.first-copy-out {
		background-color: var(--button-white);
		border-top-style: solid;
		border-top-color: #0000001a;
		border-bottom-color: #3333330d;
		display: none;
	}

	.nav-link.first-copy-out {
		background-color: var(--button-white);
		border-top-style: solid;
		border-top-color: #0000001a;
		border-bottom-color: #3333330d;
	}

	.nav-link.first-copy-out:hover {
		background-color: var(--button-white);
		color: #000;
	}

	.nav-link.first-copy-out.upper {
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first-copy-out.upper:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link.first-copy-out.upper-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first-copy-out.upper-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link.first-copy-out.upper-top {
		color: var(--white);
		background-color: #fff0;
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first-copy-out.upper-top:hover {
		color: var(--button-white);
		background-color: #fafbfc00;
	}

	.nav-link.first-copy-out.upper-top-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link.first-copy-out.upper-top-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.top-header-wrapper {
		display: flex;
	}

	.two-part-drop-list-back-arrow {
		opacity: 0.6;
		color: #0000;
		text-align: center;
		background-image: url("/20181016082423/assets/images/icon_arrow2_left_wht_50.svg");
		background-position: 0;
		background-repeat: no-repeat;
		background-size: 11px 12px;
		min-width: 0.625em;
		margin-right: 0.3125em;
		display: inline-block;
	}

	.header-bar-column {
		flex: 0 auto;
		width: auto;
		margin-top: 0;
		margin-bottom: 0;
		display: none;
	}

	.header-bar-column.header-bar-left-column {
		flex: 0 auto;
		width: auto;
		margin-right: auto;
		padding: 0;
		display: none;
	}

	.secondary-nav-list-link {
		color: #000;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		margin-left: 0;
		margin-right: 0;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
	}

	.secondary-nav-list-link:hover {
		color: #000;
		background-color: #0000;
	}

	.secondary-nav-list-link.account {
		display: flex;
	}

	.logo {
		flex-shrink: 0;
		order: -1;
		width: 180px;
		height: auto;
		margin: 0 0;
		padding-top: 0;
		position: relative;
		top: 0;
		left: 10px;
	}

	.icon-wrapper {
		border-left-style: none;
		border-left-width: 0;
		padding-left: 0;
	}

	.nav-bar-dropdown {
		padding-left: 0;
		display: none;
	}

	.secondary-nav-icon {
		width: 1.5rem;
		height: 1.5rem;
		margin-bottom: 0;
	}

	.secondary-nav-icon.account {
		width: 1.25rem;
		height: 1.25rem;
	}

	.account-link {
		width: 1.5rem;
		height: 1.5rem;
		margin-top: 0;
		margin-bottom: 0.5rem;
	}

	.header-bar-wrapper {
		border-bottom-color: #33333326;
		width: 100%;
		padding: 0 2rem;
		display: none;
	}

	.cart-link {
		width: 1.5rem;
		height: 1.5rem;
	}

	.logo-image {
		width: 150px;
		margin-top: 0;
		display: block;
		transform: translate(0);
	}

	.secondary-nav-list {
		flex-grow: 1;
		max-width: none;
		height: 3rem;
		max-height: 3rem;
		margin-top: 0;
		margin-bottom: 0;
	}

	.nav-link-2 {
		z-index: 140;
		color: #fff;
		text-align: left;
		text-transform: capitalize;
		width: 100%;
		margin-bottom: 1px;
		margin-right: 0;
		padding: 1em 1rem 1em 1.5rem;
		font-size: 1rem;
		font-weight: 500;
		line-height: 1.15rem;
		text-decoration: none;
		transition: box-shadow 0.2s, padding 0.2s, background-color 0.2s, color 0.2s, font-size 0.2s;
		display: block;
		box-shadow: 0 1px #ffffff40;
	}

	.nav-link-2:hover {
		box-shadow: none;
		color: #3eedb6;
		background-color: #fafbfc0d;
		padding-left: 1.75rem;
		padding-right: 0.75rem;
	}

	.nav-link-2.w--current {
		color: #fff;
		background-color: #0000;
		box-shadow: inset 5px 0 #3128b1, 0 1px #ffffff40;
	}

	.nav-link-2.w--current:hover {
		color: #fff;
		background-color: #0000;
	}

	.nav-link-2.mobile-list-back {
		box-shadow: none;
		color: #3eedb6;
		letter-spacing: 0;
		text-transform: capitalize;
		cursor: pointer;
		background-color: #fafbfc0d;
		padding-left: 0.5625rem;
		font-size: 1rem;
		line-height: 1.15rem;
	}

	.nav-link-2.mobile-list-back:hover {
		color: #fff;
		box-shadow: 0 1px #ffffff40;
	}

	.nav-link-2.two-part-mobile {
		float: left;
		box-shadow: none;
		color: #1a2238;
		letter-spacing: 0;
		text-transform: capitalize;
		border-bottom: 1px solid #3333330d;
		width: 90%;
		padding: 1rem 2rem;
		font-size: 1rem;
		font-weight: 500;
		line-height: 20px;
		text-decoration: none;
		transition-property: none;
		display: none;
	}

	.nav-link-2.two-part-mobile:hover {
		box-shadow: none;
		color: #ff6a3d;
		background-color: #fafbfc;
		border-bottom-color: #33333308;
		flex-grow: 0;
		width: 90%;
		padding-left: 2rem;
		font-style: normal;
	}

	.nav-menu {
		color: #fff;
		background-color: #fff;
		margin-top: 0;
	}

	.search-icon {
		min-height: 2.8rem;
		padding-top: 0;
		padding-bottom: 0;
	}

	.two-part-button-toggle {
		float: right;
		color: #0000;
		text-align: center;
		cursor: pointer;
		background-color: #1a2238;
		background-image: url("/20181016082423/assets/images/chevron-right_white.svg");
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 1em 1em;
		width: 10%;
		margin-bottom: 0;
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: 11px;
		line-height: 20px;
		display: none;
	}

	.two-part-button-toggle:hover {
		background-color: #9daad4;
	}

	.two-part-button-wrap {
		float: none;
		border-bottom: 1px #fafbfc0d;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		max-width: none;
		display: flex;
		position: static;
	}

	.two-part-button-wrap:hover {
		background-color: #0000;
	}

	.slider-two {
		height: 40vh;
	}

	.icon-7 {
		margin-top: auto;
	}

	.slider-three {
		height: 40vh;
	}

	.slide-inner-content {
		padding-left: 5rem;
		padding-right: 5rem;
		top: 0%;
		left: 0%;
	}

	.slide-inner-content.out {
		display: none;
	}

	.slider {
		height: 40vh;
	}

	.slide-heading {
		max-width: 500px;
		font-size: 3rem;
		line-height: 3.15rem;
	}

	.slider-one {
		height: 100%;
	}

	.text-block.hero-image {
		font-size: 1rem;
	}

	.icon-6 {
		margin-top: auto;
	}

	.promo2box {
		width: 20%;
		height: 150px;
	}

	.promo2text {
		width: auto;
	}

	.div-block-5 {
		height: auto;
	}

	.client-logo {
		width: auto;
		margin-bottom: 30px;
		margin-left: 0;
		margin-right: 0;
	}

	.clients-container {
		width: auto;
		max-width: none;
		padding-left: 20px;
		padding-right: 20px;
	}

	.client-logos {
		flex-flow: row;
		justify-content: space-between;
	}

	.map-pin {
		width: 35px;
		height: 50px;
		top: 166px;
		left: 134px;
	}

	.map-pin.latin-america {
		top: 203px;
		left: 112px;
	}

	.map-pin.latin-america._2 {
		z-index: 3;
		top: 60px;
		left: 250px;
	}

	.map-pin-tooltip.north-america {
		display: none;
	}

	.image-map {
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.text-block-6 {
		font-size: 70px;
	}

	.sneak-title {
		text-align: center;
	}

	.arrow-image {
		left: 275px;
		right: auto;
	}

	.section-2 {
		padding-top: 118px;
		padding-bottom: 118px;
	}

	.grid-3-columns {
		grid-template-columns: 1fr 1fr;
	}

	.product-card-image {
		width: 100%;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.team_name-copy {
		margin-top: 0;
	}

	.three-box-content {
		flex-direction: column;
		align-items: center;
	}

	.heading-59 {
		margin-top: 0.5rem;
	}

	.team_item_promo {
		width: 80%;
		margin-bottom: 30px;
	}

	.team_item_promo.first {
		margin-top: 30px;
	}

	.text-cta-2 {
		width: auto;
		font-size: 12px;
	}

	.team_content-2 {
		justify-content: flex-start;
	}

	.team_image-2 {
		height: 20rem;
	}

	.button-with-arrow-copy {
		width: 87%;
		display: none;
	}

	.spotlights {
		padding: 40px 5%;
	}

	.f-testimonial-background {
		height: 20%;
	}

	.f-paragraph-small-2 {
		color: #242424;
	}

	.f-testimonial-slider-mask {
		width: 250px;
	}

	.f-slide-nav-hidden {
		bottom: 11px;
	}

	.image-8 {
		margin-top: 0px;
		width: auto;
	}

	.mascot {
		display: flex;
	}

	.testimonials-carousel {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.footer-social-icons-wrap {
		flex-flow: column;
		align-self: center;
		align-items: center;
	}

	.div-block-17 {
		align-items: flex-start;
	}

	.footer-social-icon-link {
		width: 1.8rem;
		height: 1.8rem;
	}

	.link-list-title {
		margin-bottom: 1rem;
		font-family: Montserrat, sans-serif;
	}

	.footercol {
		width: 40%;
		padding-left: 20px;
		padding-right: 20px;
	}

	.footercol.out {
		padding-left: 0;
		padding-right: 0;
	}

	.footer-info {
		width: auto;
		max-width: none;
		padding-left: 10px;
		padding-right: 10px;
	}

	.large-card-section {
		grid-template-columns: 1fr 1fr;
		width: auto;
		max-width: none;
		min-height: auto;
		padding-left: 0;
		padding-right: 0;
	}

	.card-wrapper {
		width: auto;
		height: 250px;
		margin: 0.5rem;
	}

	.overflow-wrapper {
		width: 100%;
		height: auto;
	}

	.large-card-background {
		width: 100%;
		max-width: none;
		height: auto;
	}

	.advertisers_img {
		width: 150px;
		height: auto;
	}

	.button-3.c-11-topbar {
		width: auto;
	}

	.button-3.newslettersub {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.button-3.newslettersub.marginleft {
		margin-right: 5px;
	}

	.button-3.newslettersub.marginright {
		margin-left: 5px;
	}

	.button-3.newslettersub.marginleft-copy {
		margin-right: 5px;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.button-3.newslettersub.marginleft-copy.out {
		display: none;
	}

	.button-3.newslettersub.marginleft-copy.nodec {
		display: flex;
	}

	.button-3.newslettersub.marginright-copy {
		margin-left: 5px;
	}

	.button-3.newslettersub.onlydesktop-copy {
		display: none;
	}

	.button-3.newslettersub.marginright-copy-copy {
		margin-left: 5px;
		display: none;
	}

	.btn-txt {
		display: block;
	}

	.btn-icon-wrapper {
		padding-top: 0;
	}

	.button-subscribe.main-sub {
		width: auto;
	}

	.allertmessage {
		flex-flow: row;
		justify-content: flex-end;
		align-items: center;
		width: auto;
		max-width: none;
		margin-right: auto;
		display: flex;
		position: static;
	}

	.allert-close {
		grid-column-gap: 0px;
		justify-content: flex-end;
		display: flex;
		position: static;
	}

	.text-area {
		grid-column-gap: 0px;
		width: auto;
	}

	.info-banner-1-copy {
		justify-content: center;
		align-items: center;
		width: auto;
		margin-left: auto;
		margin-right: auto;
		padding-left: 0%;
		padding-right: 0%;
		display: flex;
	}

	.alert-banner {
		justify-content: center;
		align-items: flex-end;
		width: auto;
		display: block;
	}

	.alertmain {
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		display: block;
		overflow: hidden;
	}

	.text-alert {
		margin-left: 10px;
		margin-right: 5px;
		font-size: 16px;
	}

	.mask {
		height: auto;
		margin-top: 0;
	}

	.image-18 {
		z-index: 0;
		width: 80%;
		max-width: none;
		display: block;
		position: relative;
		top: 0;
		overflow: hidden;
	}

	.icon-wrapper-top {
		border-left-style: none;
		border-left-width: 0;
		padding-left: 0;
	}

	.link-3 {
		font-family: Merriweather, serif;
	}

	.spotlight-container {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		display: none;
	}

	.welcomecon {
		padding-top: 10px;
	}

	.hero_sub-heading-copy {
		max-width: none;
		font-size: 6vw;
	}

	.hero_info-text-copy {
		max-width: none;
		padding-top: 10px;
		padding-left: 40px;
		padding-right: 40px;
	}

	.image-20 {
		max-width: 80%;
	}

	.div-block-187,
	.responsiveonly {
		display: flex;
	}

	.padding-section-medium {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.text-block-3 {
		color: #242424;
		font-size: 1rem;
		line-height: 24px;
	}

	.spotlightscontainer {
		border-radius: 0;
		height: auto;
	}

	.spotlightswrapper {
		background-color: #ffffff1a;
		width: auto;
		padding-left: 0;
		padding-right: 15px;
	}

	.div-block-12 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		padding: 20px;
		display: grid;
	}

	.image-21.img-zoom {
		border-radius: 0;
		width: auto;
		max-width: 100%;
		height: 40vw;
	}

	.news-section {
		padding: 0 0 0 0%;
	}

	.heading-2 {
		color: #374957;
		text-transform: capitalize;
		font-family: Pacifico, sans-serif;
		font-size: 1.5rem;
		font-weight: 400;
	}

	.spotlightsnodesktop {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		margin-left: 0;
		margin-right: 0;
		padding: 0;
		display: grid;
	}

	.spotlight-container-copy {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		display: none;
	}

	.spotlight-container-nodesktop {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		display: flex;
	}

	.link-4 {
		letter-spacing: 0;
		text-transform: capitalize;
	}

	.right-col-item-wrap {
		margin-left: 0;
	}

	.inside-page-container.white-background {
		flex-wrap: wrap;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.breadcrumb-link-2 {
		margin-top: 0.25rem;
	}

	.left-nav-wrapper-2 {
		display: none;
	}

	.element-intro-header-wrap {
		width: 90%;
		max-width: 100%;
		margin-bottom: 20px;
		margin-left: 20px;
	}

	.buttons-wrap {
		grid-row-gap: 20px;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr 1fr;
		width: 100%;
	}

	.link_blue {
		color: #012045;
	}

	.inside-page-header-section-2 {
		height: 450px;
		min-height: 200px;
		position: relative;
	}

	.paragraph-9 {
		font-size: 16px;
		line-height: 26px;
	}

	.div-block-170 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		padding-left: 0;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.breadcrumbs-wrapper.old-breadcrump {
		display: none;
	}

	.inside-page-content-left-right {
		margin-top: 0;
		margin-left: 0%;
		margin-right: 0%;
	}

	.element-category-section {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.left-side-col {
		width: 100%;
		margin-top: 1.5rem;
		margin-left: 0%;
		display: block;
	}

	.inside-hero-section-2 {
		max-width: none;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.content-container {
		width: 100%;
		min-width: auto;
		padding-left: 0;
	}

	.content-container.threequarterwidth {
		width: 100%;
	}

	.content-container.threequarterwidth.fullpage-size {
		border-left-width: 0;
		border-right-width: 0;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.right-nav-2 {
		flex-direction: column;
		width: 100%;
		max-width: none;
		padding-left: 20px;
		display: flex;
	}

	.paragraph-29 {
		font-size: 16px;
		line-height: 26px;
	}

	.div-block-188 {
		background-color: var(--button-white);
		padding-left: 20px;
		padding-right: 20px;
	}

	.content-container-full {
		width: 100%;
		min-width: auto;
		padding-left: 0;
	}

	.content-container-full.threequarterwidth {
		width: 100%;
	}

	.content-container-full.threequarterwidth.fullpage-size {
		border-left-width: 0;
		border-right-width: 0;
	}

	.breadcrumbs-wrapper-full {
		margin-top: 0.5rem;
		padding-left: 0;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.breadcrumbs-wrapper-full.old-breadcrump {
		display: none;
	}

	.inside-page-container-full.white-background {
		flex-wrap: wrap;
		width: 100%;
		max-width: none;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.element-intro-header-wrap-full {
		width: 90%;
		max-width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 1.2rem;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.div-block-189 {
		width: 100%;
		max-width: none;
	}

	.link_blue-copy {
		color: #012045;
	}

	.title-full {
		width: 100%;
		max-width: none;
	}

	.div-block-192 {
		display: flex;
	}

	.icon-wrapper-top-tablet {
		border-left-style: none;
		border-left-width: 0;
		margin-right: 20px;
		padding-left: 0;
		display: flex;
	}

	.nav-link-copy.first.upper-top {
		color: var(--white);
		background-color: #fff0;
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
		display: none;
	}

	.nav-link-copy {
		color: #1a2238;
		border-top: 1px #000;
		border-bottom: 1px solid #3333330d;
		justify-content: flex-start;
	}

	.nav-link-copy:hover {
		color: #ff6a3d;
		background-color: #fafbfc;
		border-bottom: 1px solid #3333330d;
	}

	.nav-link-copy.last {
		color: #1a2238;
	}

	.nav-link-copy.last:hover {
		color: #ff6a3d;
		border-bottom-color: #3333330d;
	}

	.nav-link-copy.first {
		background-color: var(--button-white);
		border-top-style: solid;
		border-top-color: #0000001a;
		border-bottom-color: #3333330d;
	}

	.nav-link-copy.first:hover {
		background-color: var(--button-white);
		color: #000;
	}

	.nav-link-copy.first.upper {
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link-copy.first.upper:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link-copy.first.upper-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link-copy.first.upper-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link-copy.first.upper-top {
		color: var(--white);
		background-color: #fff0;
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link-copy.first.upper-top:hover {
		color: var(--button-white);
		background-color: #fafbfc00;
	}

	.nav-link-copy.first.upper-top-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link-copy.first.upper-top-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link-copy.first-copy {
		background-color: var(--button-white);
		border-top-style: solid;
		border-top-color: #0000001a;
		border-bottom-color: #3333330d;
		display: none;
	}

	.nav-link-copy.first-copy {
		background-color: var(--button-white);
		border-top-style: solid;
		border-top-color: #0000001a;
		border-bottom-color: #3333330d;
	}

	.nav-link-copy.first-copy:hover {
		background-color: var(--button-white);
		color: #000;
	}

	.nav-link-copy.first-copy.upper {
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link-copy.first-copy.upper:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link-copy.first-copy.upper-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link-copy.first-copy.upper-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.nav-link-copy.first-copy.upper-top {
		color: var(--white);
		background-color: #fff0;
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link-copy.first-copy.upper-top:hover {
		color: var(--button-white);
		background-color: #fafbfc00;
	}

	.nav-link-copy.first-copy.upper-top-copy {
		border-top-style: solid;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.nav-link-copy.first-copy.upper-top-copy:hover {
		color: #000;
		background-color: #fafbfc00;
	}

	.div-block-194 {
		background-color: var(--white);
	}

	.subscribebtnmenu {
		background-color: #fff;
		padding: 1.3rem 2rem;
		display: none;
	}

	.book_description-title-mobile {
		font-size: 26px;
	}

	.slider-three2 {
		height: 40vh;
	}

	.footercol-follow {
		width: 40%;
		padding-left: 20px;
		padding-right: 20px;
	}

	.link-list-title-copy,
	.link-list-title-2,
	.link-list-title-3 {
		margin-bottom: 1rem;
		font-family: Montserrat, sans-serif;
	}
}

@media screen and (max-width: 767px) {
	.image-21.img-zoom {
		border-radius: 0;
		width: 100%;
		max-width: 100%;
		height: auto;
	}

	.book_object {
		width: 180px;
	}

	.book_object-wrapper {
		padding-left: 20px;
	}

	.hero_container {
		max-width: none;
		height: auto;
		top: -143px;
	}

	.section_hero {
		background-position: 50%;
		background-size: auto;
		background-attachment: scroll;
		height: 35vh;
	}

	.footer_bottom {
		padding: 20px;
	}

	.hero_title-wrapper {
		width: auto;
		height: auto;
		margin-bottom: 10px;
	}

	.footer_sub-title {
		text-align: center;
		margin-bottom: 5px;
		font-size: 11px;
	}

	.lottie-bg {
		height: 370px;
	}

	.lottie-wrap {
		top: 0%;
	}

	.menu-nav-button {
		flex-shrink: 0;
		align-items: center;
		height: auto;
		padding-left: 0;
		padding-right: 0;
		display: block;
	}

	.menu-button-icon-2 {
		align-items: center;
		height: auto;
		font-size: 2rem;
		line-height: 2rem;
		display: block;
	}

	.header-contents-wrap {
		background-color: var(--bright-blue);
		height: 90px;
	}

	.secondary-nav-list-item {
		flex: 1;
		max-width: 100%;
	}

	.navbar {
		height: 90px;
	}

	.nav-link.first-copy,
	.nav-link.first-copy-out,
	.top-header-wrapper {
		display: flex;
	}

	.header-bar-column.header-bar-left-column {
		font-size: 0.75rem;
		line-height: 1.5em;
	}

	.secondary-nav-list-link {
		margin-left: 0;
		margin-right: 1.5rem;
		padding-left: 0;
		padding-right: 0;
		display: flex;
	}

	.logo {
		align-self: center;
		width: 150px;
		height: 80px;
		margin: 0 0;
		padding-top: 0.5rem;
		padding-left: 0;
		position: relative;
		top: auto;
	}

	.secondary-nav-icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.secondary-nav-icon.account {
		width: auto;
	}

	.account-link {
		width: 1.5rem;
		height: 1.5rem;
	}

	.header-bar-wrapper {
		display: none;
	}

	.cart-link {
		width: 1.5rem;
		height: 1.5rem;
	}

	.logo-image {
		width: 130px;
		margin-top: 0;
		margin-bottom: 0.25rem;
		display: block;
		transform: translate(0, -8px);
	}

	.secondary-nav-list {
		flex: auto;
		align-items: stretch;
		max-width: 84%;
		height: auto;
	}

	.nav-link-2.two-part-mobile {
		width: 85%;
	}

	.two-part-button-toggle {
		width: 15%;
	}

	.icon-7 {
		margin-left: 10px;
	}

	.slide-inner-content {
		padding: 2rem 4rem;
	}

	.slider {
		height: 35vh;
	}

	.slide-heading {
		justify-content: center;
		align-items: center;
		font-size: 3rem;
		display: block;
	}

	.text-block.hero-image {
		margin-bottom: 32px;
	}

	.icon-6 {
		margin-right: 10px;
	}

	.div-block-175 {
		flex-wrap: wrap;
		justify-content: center;
	}

	.promo2box {
		width: 50%;
	}

	.button-2 {
		width: 13.75rem;
	}

	.client-logo {
		width: 50%;
		margin-bottom: 35px;
	}

	.section-heading {
		font-size: 3em;
	}

	.clients-container {
		padding-left: 20px;
		padding-right: 20px;
	}

	.client-logos {
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.clients {
		padding-bottom: 40px;
	}

	.map-pin {
		width: 25px;
		height: 40px;
		top: 198px;
		left: 40px;
	}

	.map-pin.latin-america {
		top: 224px;
		left: 38px;
	}

	.map-pin.latin-america._2 {
		top: 62px;
		left: 250px;
	}

	.button-2-copy {
		width: 13.75rem;
	}

	.text-block-6 {
		font-size: 55px;
	}

	.arrow-image {
		top: -28px;
		left: 210px;
	}

	.section-2 {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	.grid-3-columns {
		grid-template-columns: 1fr;
	}

	.grid-3-columns.gap-row-48px {
		grid-row-gap: 40px;
	}

	.badge-primary {
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.badge-primary.small {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.mg-top-32px {
		margin-top: 24px;
	}

	.text-300 {
		font-size: 18px;
		line-height: 20px;
	}

	.btn-secondary {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.team_name-copy {
		text-align: center;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.2;
	}

	.heading-59 {
		text-align: center;
		margin-bottom: 0.25rem;
		font-size: 22px;
		line-height: 28px;
	}

	.team_content-2 {
		right: 10%;
	}

	.team_image-2 {
		height: 18rem;
	}

	.f-h3-heading-4 {
		font-size: 40px;
	}

	.f-testimonial-slider-mask {
		width: 180px;
	}

	.f-slide-nav-hidden {
		bottom: 20px;
	}

	.testimonials-carousel {
		width: 80%;
	}

	.div-block-182 {
		flex-flow: column;
		justify-content: center;
		padding-top: 0;
	}

	.copywright {
		flex-flow: column;
		margin-left: 0%;
	}

	.footer-grip-link.white-logo {
		width: 133px;
		height: 30px;
	}

	.grip-logo-text-box-2.webdevby-white {
		font-size: 0.5rem;
	}

	.div-block-183 {
		justify-content: center;
		margin-top: 10px;
		margin-right: 0%;
		display: flex;
	}

	.footer-social-icons-wrap {
		margin-top: 0;
	}

	.div-block-17 {
		flex-direction: column;
		justify-content: center;
	}

	.footer-social-icon-link {
		margin-left: 0;
		margin-right: 1rem;
	}

	.link-list-title {
		margin-bottom: 0.5rem;
	}

	.footercol,
	.footer-info {
		padding-left: 0;
		padding-right: 0;
	}

	.large-card-section {
		width: 80%;
		display: none;
	}

	.card-wrapper {
		width: auto;
		font-size: 14px;
		line-height: 24px;
	}

	.large-card-background {
		width: auto;
		height: 16rem;
		margin: 0;
		font-size: 14px;
		line-height: 24px;
	}

	.large-card-text-wrap {
		font-size: 14px;
		line-height: 24px;
	}

	.btn_11 {
		font-size: 3rem;
	}

	.btn_11_arrow {
		width: 3rem;
		height: 3rem;
	}

	.allertmessage {
		flex-flow: row;
		justify-content: center;
		align-items: center;
	}

	.text-area {
		text-align: right;
		font-size: 14px;
	}

	.info-banner-1-copy {
		background-image: none;
		background-position: 0 0;
		background-repeat: repeat;
		background-size: auto;
	}

	.alert-banner {
		flex-flow: row;
		align-items: flex-start;
		width: auto;
		margin-right: 10px;
		font-size: 14px;
		display: block;
	}

	.text-alert {
		text-align: center;
		font-size: 14px;
		font-weight: 400;
	}

	.mask {
		height: auto;
		top: 0;
	}

	.uui-button-7 {
		font-size: 1rem;
	}

	.uui-button-7:hover {
		background-color: #0871b9;
		border-color: #0871b9;
	}

	.primary-button {
		text-align: center;
	}

	.padding-section-medium {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.padding-global {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.features_grid {
		grid-template-columns: 1fr;
	}

	.features_header {
		grid-row-gap: 2rem;
		grid-template-columns: 1fr;
	}

	.div-block-6 {
		margin-bottom: 0;
	}

	.spotlightswrapper {
		width: 80%;
		margin-bottom: 20px;
	}

	.div-block-12 {
		flex-wrap: wrap;
		justify-content: center;
	}

	.features_components {
		grid-row-gap: 2rem;
	}

	.news-section {
		z-index: 500;
		background-color: #fff;
		flex-wrap: wrap;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;
	}

	.spotlightsnodesktop {
		flex-wrap: wrap;
		grid-template-columns: 1fr;
		justify-content: center;
	}

	.footer_linksy {
		text-align: center;
		margin-top: 5px;
		margin-bottom: 5px;
		margin-left: 10px;
		font-size: 11px;
	}

	.right-col-item-wrap {
		margin-left: 0;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.inside-page-container.white-background {
		background-color: var(--button-white);
		padding-top: 0;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.heading-1inside {
		font-size: 36px;
	}

	.heading-1 {
		margin-bottom: 0;
		font-size: 35px;
		line-height: 45px;
	}

	.block-quote-2 {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.buttons-wrap {
		grid-row-gap: 20px;
		flex-flow: column;
		align-items: flex-start;
		display: flex;
	}

	.h5-header-link-2 {
		font-size: 1.125rem;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.h4-header-link-2 {
		font-size: 1.25rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		padding-left: 0;
		font-size: 0.85rem;
	}

	.intro-paragraph-2 {
		font-size: 1.125rem;
	}

	.inside-page-content-left-right {
		margin-top: 0;
		margin-left: 0%;
		margin-right: 0%;
	}

	.h3-header-link-2 {
		font-size: 1.5rem;
	}

	.imgblock {
		min-height: auto;
	}

	.breadcrumbs-list-2 {
		margin-bottom: 1.5rem;
	}

	.element-category-section {
		margin-top: 0;
	}

	.inside-hero-section-2 {
		background-color: #e4f3fa;
		width: 100%;
		max-width: none;
	}

	.content-container.threequarterwidth.fullpage-size {
		width: auto;
		min-width: auto;
		padding-bottom: 0;
	}

	.h2-header-link-2 {
		font-size: 1.75rem;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.image-22 {
		width: 100%;
		height: 100%;
	}

	.content-container-full.threequarterwidth.fullpage-size {
		width: auto;
		min-width: auto;
		padding-bottom: 0;
	}

	.breadcrumbs-wrapper-full {
		margin-top: 0.25rem;
		padding-left: 0;
		font-size: 0.85rem;
	}

	.inside-page-container-full.white-background {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.element-category-section-full {
		margin-top: 119px;
	}

	.element-category-section-full-copy {
		margin-top: 137px;
		padding-top: 0;
	}

	.nav-link-copy.first-copy {
		display: flex;
	}

	.div-block-193 {
		justify-content: center;
		align-items: center;
		height: auto;
		display: block;
	}

	.footercol-follow {
		padding-left: 0;
		padding-right: 0;
	}

	.link-list-title-copy,
	.link-list-title-2,
	.link-list-title-3 {
		margin-bottom: 0.5rem;
	}

	.button-3.c-11-topbar-mobile {
		min-width: 125px;
		padding: 0.5rem 0rem;
	}

	.button-3.c-11-topbar-mobile.gift {
		margin-right: 10px;
	}

	.button-3.c-11-topbar-mobile.gift .btn-txt {
		font-size: 12px;
	}

	.icon-wrapper-top-tablet {
		margin-right: 10px;
	}

	.search-responive.hidden.mobile-responive {
		margin-right: 5px;
	}
}

@media screen and (max-width: 479px) {
	.book_object {
		box-shadow: none;
		width: 200px;
	}

	.image {
		box-shadow: -6px 10px 20px -3px #0000004d;
	}

	.book_container {
		flex-flow: column;
		padding-top: 0;
		padding-bottom: 40px;
	}

	.book_description {
		padding-bottom: 20px;
		padding-right: 0;
	}

	.book_object-wrapper {
		padding-top: 10px;
		padding-left: 0;
		position: relative;
		top: auto;
	}

	.book_description-wrapper {
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.book_description-year {
		text-align: center;
		margin-bottom: 30px;
		display: none;
	}

	.book_description-title {
		text-align: center;
		margin-top: 0;
		font-size: 20px;
		line-height: 25px;
		display: none;
	}

	.hero_container {
		display: flex;
		top: auto;
	}

	.section_hero {
		background-position: 50%;
		height: 25vh;
	}

	.footer_bottom {
		padding-bottom: 10px;
	}

	.hero_title-wrapper {
		margin-bottom: 10px;
	}

	.hero_info-item.is_25 {
		width: 44%;
		padding-right: 0;
	}

	.hero_info-text {
		font-size: 14px;
	}

	.footer_sub-title {
		text-align: center;
		line-height: 1.4;
	}

	.lottie-bg {
		height: 290px;
	}

	.menu-nav-button {
		border-left-style: none;
		justify-content: flex-start;
		height: 5.25rem;
		margin-left: auto;
		padding-left: 0;
		padding-right: 0;
		right: auto;
	}

	.menu-nav-button.w--open {
		color: #fff6;
	}

	.menu-button-icon-2 {
		line-height: 5.25rem;
	}

	.header-contents-wrap {
		padding-left: 0.5rem;
		padding-right: 1rem;
		box-shadow: 0 2px 5px #0003;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.navbar {
		box-shadow: none;
		height: 90px;
	}

	.nav-link.first-copy-out {
		display: none;
	}

	.top-header-wrapper {
		display: flex;
	}

	.secondary-nav-list-link {
		padding-left: 0.375rem;
		padding-right: 0.375rem;
	}

	.logo {
		width: auto;
		height: 80px;
		margin: 0;
		padding-top: 0;
		display: flex;
		top: auto;
	}

	.header-bar-wrapper {
		padding-left: 1rem;
		padding-right: 0;
		box-shadow: 0 2px 5px #0003;
	}

	.logo-image {
		width: 110px;
		margin-top: 4rem;
		margin-bottom: 0;
		transform: translate(0, -33px);
	}

	.secondary-nav-list {
		flex-grow: 1;
		order: -1;
		max-width: none;
		height: 3.5rem;
		max-height: 3.5rem;
	}

	.nav-menu {
		margin-top: 20px;
	}

	.search-icon {
		padding-left: 1.5rem;
	}

	.slider-two {
		height: 25vh;
	}

	.icon-7 {
		font-size: 1.5rem;
	}

	.slider-inner-content {
		align-items: center;
		margin-top: 0;
	}

	.right-arrow {
		display: flex;
	}

	.slider-three {
		height: 25vh;
	}

	.slide-inner-content {
		justify-content: center;
		height: 100%;
		padding-left: 2rem;
		padding-right: 2rem;
		display: flex;
		top: 0%;
	}

	.button {
		margin-bottom: 1rem;
	}

	.slider {
		height: 25vh;
	}

	.left-arrow {
		display: flex;
	}

	.slide-heading {
		text-align: center;
		max-width: 500px;
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 1.5rem;
		line-height: 2.5rem;
	}

	.text-block {
		text-align: center;
	}

	.text-block.hero-image {
		margin-bottom: 20px;
		font-size: 1rem;
		line-height: 1.25rem;
		text-decoration: none;
	}

	.icon-6 {
		font-size: 1.5rem;
	}

	.div-block-175 {
		width: 90%;
	}

	.promo2box {
		width: 80%;
	}

	.heading-43 {
		font-size: 18px;
	}

	.promo2icon {
		width: auto;
		max-width: 75px;
	}

	.promo2text {
		text-align: left;
		font-size: 0.8rem;
		line-height: 18px;
		display: flex;
	}

	.button-2 {
		width: 11.25rem;
		height: 1.8125rem;
	}

	.button-text.white {
		font-size: 0.5625rem;
	}

	.div-block-5 {
		border-radius: 0;
		width: auto;
	}

	.image-3 {
		border-radius: 0;
	}

	.client-slideshow {
		padding-top: 20px;
		padding-bottom: 0;
	}

	.clients-arrow {
		display: none;
	}

	.clients-slide-nav {
		justify-content: center;
		margin-top: 0;
		padding-top: 30px;
		display: flex;
	}

	.client-logo {
		flex-flow: row;
		justify-content: center;
		width: 50%;
		margin-bottom: 20px;
		margin-left: 0;
		margin-right: 0;
		display: flex;
	}

	.section-heading {
		text-align: left;
		font-size: 2em;
	}

	.section-heading.our {
		text-align: center;
		font-size: 26px;
	}

	.clients-container {
		padding-left: 0;
		padding-right: 0;
	}

	.client-logos {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		flex-direction: column;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		place-items: center;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		display: grid;
	}

	.clients {
		padding-left: 30px;
		padding-right: 30px;
	}

	.map-pin {
		width: 20px;
		height: 35px;
		top: 211px;
		left: 33px;
	}

	.map-pin.latin-america {
		top: 232px;
	}

	.map-pin.latin-america._2 {
		top: 30px;
		left: 140px;
	}

	.map-pin-tooltip {
		padding-left: 5px;
		padding-right: 5px;
		left: -50px;
		right: -50px;
	}

	.map-pin-tooltip.north-america {
		display: none;
	}

	.country {
		padding-right: 18px;
		font-size: 12px;
	}

	.button-2-copy {
		width: 11.25rem;
		height: 1.8125rem;
	}

	.image-map {
		width: 300px;
	}

	.promo2text-copy {
		text-align: left;
	}

	.text-block-6 {
		font-size: 30px;
	}

	.arrow-image {
		width: 40px;
		top: -15px;
		left: 115px;
		right: auto;
	}

	.section-2 {
		padding-top: 96px;
		padding-bottom: 96px;
	}

	.container-default {
		padding-left: 20px;
		padding-right: 20px;
	}

	.grid-3-columns {
		grid-template-columns: 1fr;
	}

	.grid-3-columns.gap-row-48px {
		grid-row-gap: 32px;
	}

	.badge-primary {
		padding: 14px 22px;
		font-size: 16px;
		line-height: 18px;
	}

	.badge-primary.small {
		padding: 10px 20px;
		font-size: 14px;
		line-height: 16px;
	}

	.btn-secondary {
		width: 100%;
		padding-top: 18px;
		padding-bottom: 18px;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.three-box-content {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: none;
	}

	.team_item_promo {
		width: 100%;
	}

	.team_content-2 {
		height: 70%;
	}

	.spotlights {
		padding-bottom: 0;
		overflow: visible;
	}

	.f-h3-heading-4 {
		text-align: center;
		font-size: 26px;
	}

	.f-testimonial-avatar-small {
		margin-bottom: 16px;
		margin-right: 0;
	}

	.f-container-regular-3 {
		max-width: none;
	}

	.f-testimonial-background {
		height: 55%;
		bottom: 0%;
	}

	.testimonial-4 {
		padding-top: 10px;
		padding-bottom: 70px;
	}

	.f-testimonial-slider-large {
		margin-top: 0;
		margin-bottom: 0;
		padding-bottom: 0;
		overflow: visible;
	}

	.f-testimonial-slider-mask {
		width: 100%;
		height: auto;
	}

	.f-slide-nav-hidden {
		justify-content: flex-start;
		bottom: -56px;
	}

	.f-testimonial-card {
		margin-bottom: 10px;
		padding: 10px;
	}

	.f-testimonial-author {
		flex-flow: column wrap;
		align-items: flex-start;
	}

	.promo {
		flex-flow: column;
		align-items: center;
	}

	.mascot {
		justify-content: center;
		width: 60%;
		margin-bottom: 0;
	}

	.testimonials-carousel {
		width: 100%;
	}

	.logo-footer {
		margin-bottom: 0;
	}

	.div-block-182 {
		flex-flow: column;
		align-items: center;
	}

	.footer-grip-link.white-logo {
		margin-bottom: 10px;
	}

	.link-list-title {
		justify-content: center;
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-family: Merriweather, serif;
	}

	.footercol {
		margin-bottom: 20px;
		width: auto;
	}

	.footercol.out {
		order: 3;
	}

	.footer-info {
		flex-flow: column;
		align-items: center;
	}

	.rowtitle {
		max-width: none;
	}

	.large-card-section {
		flex-flow: column;
		width: auto;
	}

	.card-wrapper {
		width: auto;
		height: auto;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.overflow-wrapper {
		width: 250px;
	}

	.large-card-background {
		width: auto;
		height: auto;
	}

	.large-card-header {
		font-size: 1rem;
	}

	.card-subtext {
		font-size: 0.6rem;
	}

	.advertisers_img {
		width: 150px;
	}

	.btn_11_arrow {
		width: 3rem;
		height: 3rem;
	}

	.button-3.c-11 {
		width: auto;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.button-3.c-11-copy {
		width: auto;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		display: none;
	}

	.button-3.c-11-topbar {
		width: auto;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.button-3.newslettersub {
		width: auto;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.button-3.newslettersub.onlydesktop {
		display: flex;
	}

	.button-3.newslettersub.marginleft-copy {
		margin-top: 0;
		margin-right: 0;
	}

	.button-3.newslettersub.marginright-copy,
	.button-3.newslettersub.marginright-copy-copy {
		margin-left: 0;
	}

	.button-3.footersub {
		width: auto;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.button-3.c-11-topbar-inside,
	.button-3.c-11-topbar-inside2,
	.button-3.c-11-topbar-inside-copy {
		width: auto;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.button-3.c-11-topbar-mobile {
		width: auto;
		padding-left: 1rem;
		padding-right: 1rem;
		display: none;
	}

	.btn-txt {
		font-size: 14px;
	}

	.button-subscribe {
		width: auto;
	}

	.footer_con {
		margin-bottom: 0;
	}

	.allertmessage {
		margin-right: auto;
		padding-left: 5px;
		padding-right: 5px;
	}

	.allert-close {
		display: flex;
	}

	.alert-banner {
		text-align: left;
		width: auto;
	}

	.text-alert {
		text-align: center;
		margin-right: 0;
	}

	.mask {
		height: 25vh;
	}

	.text-block-7,
	.text-block-8 {
		font-size: 13px;
	}

	.link-list-title-copy {
		justify-content: center;
	}

	.btn-txt-footerbtn {
		font-size: 14px;
	}

	.pop-up {
		width: 96%;
		padding: 10px;
	}

	.pop-up-icon {
		display: none;
	}

	.exit-button {
		width: 20px;
		height: 20px;
		padding: 3px;
		top: 1%;
		right: 1%;
	}

	.primary-button {
		text-align: center;
	}

	.large-card-header-2 {
		font-size: 1rem;
	}

	.card-subtext-2 {
		font-size: 0.6rem;
	}

	.hero_sub-heading-copy {
		font-size: 11vw;
	}

	.hero_info-text-copy {
		font-size: 14px;
	}

	.image-20 {
		max-width: 90%;
	}

	.responsiveonly {
		flex-flow: column;
	}

	.text-block-3 {
		border-bottom: 1px solid #b0b0b0;
		margin-left: 0;
		margin-right: 0;
		padding-bottom: 10px;
	}

	.spotlightscontainer {
		border-radius: 0;
		width: 100%;
	}

	.div-block-6 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.spotlightswrapper {
		border-bottom: 0px solid var(--bright-blue);
		border-radius: 0;
		align-items: center;
		width: auto;
		margin-bottom: 20px;
		padding: 0;
	}

	.div-block-12 {
		width: 90%;
	}

	.image-21 {
		border-radius: 0;
	}

	.image-21.img-zoom {
		width: 100%;
	}

	.news-section {
		margin-top: 60px;
		padding: 0;
	}

	.heading-2 {
		width: 100%;
		margin-top: 10px;
		margin-left: 0;
		margin-right: 0;
		font-size: 24px;
		line-height: 25px;
	}

	.spotlightsnodesktop {
		width: auto;
	}

	.spotlight-container-nodesktop {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		flex-flow: column;
		max-width: none;
	}

	.footer_linksy {
		text-align: center;
		line-height: 1.4;
	}

	.button-6.buttonstyle {
		margin-left: 0;
	}

	.inside-page-container.white-background {
		width: auto;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		display: block;
	}

	.heading-1inside {
		line-height: 38px;
	}

	.primary-button-con {
		justify-content: flex-start;
		align-self: auto;
		display: flex;
	}

	.tertiary-button-con {
		margin-top: 0;
		padding: 10px;
	}

	.tertiary-button-con.video-only._3rd {
		padding-left: 0;
	}

	.heading-1 {
		font-size: 25px;
	}

	.element-intro-header-wrap {
		margin-left: 0;
		padding: 10px 0 10px 20px;
	}

	.buttons-wrap {
		grid-row-gap: 20px;
		flex-flow: column;
		align-items: flex-start;
		display: flex;
	}

	.link_blue {
		font-size: 16px;
	}

	.div-block-170 {
		padding-left: 0;
		padding-right: 0;
	}

	.breadcrumbs-wrapper {
		margin-top: 0;
		padding-left: 0;
	}

	.breadcrumbs-wrapper.out {
		max-width: none;
	}

	.secondarystyle {
		margin-bottom: 20px;
	}

	.intro-paragraph-2 {
		line-height: 1.5rem;
	}

	.inside-page-content-left-right {
		background-color: #fff;
		margin-top: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.link_arrow_image {
		width: 15px;
		height: 15px;
	}

	.table-div {
		width: auto;
	}

	.element-category-section {
		background-color: var(--white);
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.paragraph-25.list-item {
		text-align: left;
	}

	.inside-hero-section-2 {
		background-color: var(--button-white);
		width: auto;
		max-width: none;
		margin-left: 0;
		margin-right: 0;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.content-container.threequarterwidth.fullpage-size {
		padding-left: 0;
		padding-right: 0;
	}

	.heading-60 {
		font-size: 25px;
	}

	.right-nav-2 {
		padding-top: 1rem;
		padding-left: 0;
	}

	.heading-64,
	.heading-65 {
		font-size: 25px;
	}

	.div-block-188 {
		margin-bottom: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.heading-67 {
		font-size: 25px;
	}

	.paragraph-30.list-item {
		text-align: left;
	}

	.body-2 {
		background-color: var(--white);
	}

	.content-container-full.threequarterwidth.fullpage-size {
		padding-left: 0;
		padding-right: 0;
	}

	.breadcrumbs-wrapper-full {
		margin-top: 0;
		padding-left: 0;
	}

	.breadcrumbs-wrapper-full.out {
		max-width: none;
	}

	.inside-page-container-full.white-background {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		display: block;
	}

	.element-intro-header-wrap-full {
		margin-left: 0;
		padding-left: 20px;
	}

	.link_blue-copy {
		font-size: 16px;
	}

	.subscribebtnmenu {
		display: flex;
	}

	.book_description-title-mobile {
		text-align: center;
		margin-top: 0;
		font-size: 20px;
		line-height: 25px;
		display: flex;
	}

	.book_description-year-mobile {
		text-align: center;
		justify-content: center;
		margin-bottom: 30px;
		display: flex;
	}

	.slider-three2 {
		background-position: 0 0, 100%;
		background-repeat: repeat, repeat-y;
		background-size: auto, cover;
		background-attachment: scroll, scroll;
		height: 25vh;
	}

	.footercol-follow {
		width: auto;
		display: flex;
	}

	.link-list-title-copy {
		justify-content: center;
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-family: Merriweather, serif;
	}

	.link-list-title-2 {
		justify-content: center;
		margin-top: 0;
		margin-bottom: 1rem;
		font-family: Merriweather, serif;
	}

	.link-list-title-3 {
		justify-content: center;
		margin-top: 1rem;
		margin-bottom: 2rem;
		font-family: Merriweather, serif;
	}
}

#w-node-c0fcef8a-19ed-0073-243b-2ab40155278f-2d4caabc {
	grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-bcd56b4a-da2e-d705-1901-c75c234740a6-23474068 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bcd56b4a-da2e-d705-1901-c75c234740a7-23474068 {
	justify-self: start;
}

#w-node-bcd56b4a-da2e-d705-1901-c75c234740e4-23474068 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bcd56b4a-da2e-d705-1901-c75c234740e5-23474068,
#w-node-_8fdc7dbd-333f-51ca-007b-9c5b41b37638-e3cb21b1,
#w-node-_492c5395-57d1-8be5-7d17-d046e6591916-5e8f3008,
#w-node-_348a1980-e3b3-f507-0356-e1a0c66e6718-1ec6f8d5,
#w-node-c34ca82f-41f8-e5a2-9798-15d45d1a7bff-f79ccaab {
	justify-self: start;
}


/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Webflow **********************/
/*****************************************************/
@media screen and (max-width: 1399px) and (min-width: 992px) {
	.spotlight-container {
		justify-content: center;
	}

	.flipcard-wrapper {
		width: 48%;
		max-width: 520px;
	}
}

/********************** Popup Alert *****************/
/*****************************************************/
.modal-alert {
	display: none;
	position: fixed;
	z-index: 100000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.6);
}


.modal-alert .modal-content-alert-inner {
	border-radius: 0;
	background-color: #fff;
	padding: 32px 30px;
	width: 80%;
	max-width: 450px;
	color: var(--black);
	font-family: Poppins, sans-serif;
	position: relative;
	font-size: 18px;
	line-height: 1.4;
	box-shadow: 1px 1px 10px 5px #00000040;
	padding-top: 50px;
	text-align: center;
}

.modal-alert .cms-website-content-region {
	clear: both;
}

.modal-alert .modal-content-alert {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	justify-content: center;
}

.modal-alert p {
	color: var(--black);
	font-family: Poppins, sans-serif;
	font-size: 18px;
	line-height: 1.4;
}

.modal-alert h2 {
	color: var(--blue);
	letter-spacing: 2px;
	text-transform: capitalize;
	font-family: Merriweather, serif;
	font-size: 41px;
	font-weight: 700;
	line-height: 1.1em;
}

/*********************** Header **********************/
/*****************************************************/
.alert-wrap {
	display: none;
}

.header-container {
	position: sticky;
	top: 0;
	z-index: 400;
}

.text-alert p:last-child {
	margin-bottom: 0px;
}

.search-button {
	border-radius: 50px;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	font-family: Montserrat, sans-serif;
	display: flex;
	position: relative;
	top: -8px;
}

.search-button.hidden.not {
	border: 2px solid var(--white);
}

.search-icon-3 {
	background-color: var(--blue);
	background-image: url('/assets/images/search-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 0.25px #0000;
	border-radius: 20px;
	width: 35px;
	height: 35px;
	min-height: auto;
	padding: 0 0.25rem;
	transition: all 0.3s;
}

.search-icon-3:hover {
	border-width: 0;
	border-color: var(--white);
	background-color: var(--white);
	background-image: url('/assets/images/search-icon-blue.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
}

.search-bar-2 {
	z-index: 2000;
	background-color: #fff;
	border-top: 1px solid #0000001a;
	border-radius: 40px;
	flex-direction: column;
	justify-content: space-around;
	align-items: stretch;
	width: 500px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1rem;
	padding-right: 1rem;
	display: none;
	position: absolute;
	inset: 16% 40px auto auto;
	box-shadow: 0 2px 5px #0003;
}

.search-bar-wrap-2 {
	justify-content: space-between;
	align-items: center;
	height: 100%;
	margin-bottom: 0;
	padding-right: 15px;
	display: flex;
}

.search-input-2 {
	background-color: #0000;
	border: 1px #000;
	width: 95%;
	margin-bottom: 0;
	padding: 1.8rem 3.1rem 1.8rem 1rem;
	font-size: 0.85rem;
	font-weight: 300;
	position: relative;
}

.div-block-120 {
	cursor: pointer;
	background-image: url('/assets/images/close-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	width: 25px;
	height: 25px;
}

.search-responive.hidden.mobil,
.search-responive.hidden.mobile-responive {
	border: 2px solid var(--white);
}

.search-responive {
	border-radius: 50px;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	font-family: Montserrat, sans-serif;
	display: flex;
}

.search-input-2:focus {
	outline: 1px solid transparent;
}

@media (max-width: 991px) {
	.search-icon-3 {
		background-size: 17px;
		width: 35px;
		height: 35px;
		min-height: auto;
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-icon-3:hover {
		background-size: 17px;
	}

	.search-bar-2 {
		margin-right: 2rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		top: 10%;
		right: 20px;
	}

	.search-bar-wrap-2 {
		display: flex;
	}

	.search-input-2 {
		padding-top: 1.55rem;
	}

	.search-responive.hidden.mobile-responive {
		width: 39px;
		height: 39px;
		margin-left: 0;
		margin-right: 10px;
		padding-right: 0;
		display: flex;
	}

	.search-bar-2 {
		width: 400px;
	}
}

@media screen and (max-width: 767px) {
	.search-icon-3 {
		background-size: 17px;
		width: 35px;
		height: 35px;
	}

	.search-bar-2,
	.search-bar-4 {
		min-width: auto;
		margin-right: auto;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		display: none;
	}
}

@media screen and (max-width: 479px) {
	.search-button {
		margin-left: 0;
		margin-right: 0.5rem;
	}

	.search-icon-3 {
		border: 2px solid var(--white);
		background-size: 17px;
		width: 35px;
		height: 35px;
		margin-left: 0;
	}

	.search-icon-3:hover {
		border-width: 2px;
	}

	.search-bar-2,
	.search-bar-4 {
		width: 80%;
		display: none;
		top: 10%;
	}

	.search-responive {
		margin-left: 0;
		margin-right: 0.5rem;
	}

	.search-responive.hidden.mobile-responive {
		border-style: none;
		height: 100%;
		margin-top: 28px;
		margin-bottom: 0;
	}
}

/***************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu {
	display: flex;
	flex-grow: 1;
	z-index: 1000;
	align-items: flex-end;
	justify-content: flex-end;
}

.accesible-navigation-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
	align-items: center;
}

.accesible-navigation-menu li.menu-li {
	position: relative;
}

.accesible-navigation-menu li.menu-li#menu-item-subscription:before {
	position: absolute;
	content: "";
	display: inline-block;
	background-image: url('/assets/images//account-icon-new.svg');
	width: 27px;
	height: 21px;
	background-size: 27px 21px;
	background-repeat: no-repeat;
	background-position: center left;
	top: 20px;
}

.accesible-navigation-menu li.menu-li .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	transition: max-height 100ms ease 0s, opacity 100ms ease 0s;
	height: 0;
	margin-top: 0px;
	width: 100%;
	padding-left: 0px;
	background-position: 25px top;
	background-repeat: no-repeat;
	background-size: 24px;
}

.accesible-navigation-menu li.menu-li .container-wrapper ul {
	display: none;
	margin: 0px;
	list-style-type: none;
	width: 100%;
	flex-wrap: wrap;
	border-top: 4px solid var(--medium-aquamarine);
	background-color: #444545;
}

.accesible-navigation-menu li.menu-li .container-wrapper ul li {
	width: 100%;
	text-align: left;
}

.accesible-navigation-menu li.menu-li .container-wrapper ul li a {
	background-color: #ededed;
	color: #000;
	text-transform: capitalize;
	font-weight: 400;
	transition: all 0.2s;
	padding: 10px 20px;
	display: inline-block;
	width: 100%;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	font-size: 1rem;
}

.accesible-navigation-menu li.menu-li .container-wrapper ul li a:hover {
	background-color: #0088cd;
	color: #fff;
}

.accesible-navigation-menu li.menu-li.open .container-wrapper,
.accesible-navigation-menu li.menu-li:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	margin-top: -13px;
	height: auto;
	padding-top: 12px;
	min-width: 200px;
	max-width: 200px;
}

.accesible-navigation-menu li.menu-li.open .container-wrapper ul,
.accesible-navigation-menu li.menu-li:hover .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 1135px) {
	.nav-link.first {
		font-size: 1rem;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.accesible-navigation-menu li.menu-li#menu-item-subscription:before {
		left: -10px;
	}
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mm-navbar {
	min-height: 60px;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #fff;
}

.mm-listitem:after {
	left: 0px;
}

.mm-wrapper_opened .w-nav-overlay {
	display: none !important;
}

.mm-wrapper_opened .header-container {
	z-index: 100 !important;
}

.mobile-navigation-menu li a.nav-a {
	color: #1a2238;
	padding: 20px 25px;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
}

.mm-navbar__title > span {
	color: #1a2238;
	font-family: Merriweather, serif;
	font-size: 1.1rem;
}

.mobile-navigation-menu li > a.nav-a:hover,
.mobile-navigation-menu li > a.nav-a.active {
	text-decoration: underline;
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-listitem_selected > .mm-listitem__text {
	background: transparent;
}

.mm-listitem_vertical > .mm-panel {
	padding: 0px;
}

.mm-menu_position-top {
	height: 65vh;
}

.susbcribe-mobile-menu {
	display: none;
}

@media screen and (max-width: 479px) {
	.susbcribe-mobile-menu {
		display: block;
		padding: 20px 15px;
		padding-bottom: 0px;
	}

	.susbcribe-mobile-menu table tr td:first-child {
		padding-right: 10px;
	}

	.susbcribe-mobile-menu:after {
		border-color: transparent;
	}

	.susbcribe-mobile-menu a {
		width: 150px !important;
	}
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

.cbtext p:last-child {
	margin-bottom: 0px;
}

.cbtext a {
	color: #333;
	text-decoration: underline;
}

.cbtext a:hover {
	text-decoration: none;
}

.flipcard-wrapper {
	color: #333;
}

.client-slideshow {
	padding-right: 40px;
	padding-left: 40px;
}

.client-slideshow .clients-arrow.w-slider-arrow-left {
	left: -50px;
}

.client-slideshow .clients-arrow.w-slider-arrow-right {
	right: -50px;
}

.client-slideshow .slick-dots li button:before {
	font-size: 20px;
}

.client-slideshow .slick-dots li {
	margin: 0 2px;
}

.testimonials-carousel .w-slider-mask {
	width: 100%;
}

.testimonials-carousel .f-margin-bottom-40 {
	width: 100%;
}

.testimonials-carousel .slick-track {
	display: flex;
}

.testimonials-carousel .slick-initialized .slick-slide {
	height: auto;
}

.testimonials-carousel .slick-initialized .f-testimonial-card {
	height: 100%;
}

.testimonials-carousel .f-paragraph-small-2,
.testimonials-carousel .f-paragraph-small-2 p {
	overflow-wrap: break-word;
}

.partner-carousel-link {
	display: inline-block;
}

.flipcard-wrapper .large-card-text-wrap {
	transition: none;
}

.flipcard-wrapper:hover .large-card-text-wrap {
	display: none;
}

.more-link.last-link {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 479px) {
	.more-link.last-link {
		padding-top: 20px;
	}
}

@media screen and (max-width: 991px) {
	.main.slider {
		margin-top: 0 !important;
	}
}

@media screen and (max-width: 767px) {
	.welcomecon {
		margin-top: 40px;
	}
}

@media screen and (max-width: 479px) {
	.welcomecon {
		margin-top: 0;
	}

	.text-block-6.small {
		font-size: 28px;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}

.slider-text p:last-child {
	margin-bottom: 0px;
}

.main.slider {
	margin-top: -110px;
}

.f-testimonial-card-outer {
	margin-left: 10px;
	margin-right: 10px;
	padding-top: 40px;
	padding-bottom: 40px;
}

.f-testimonial-card {
	min-height: 190px;
}

.f-paragraph-small-2 {
	text-align: left;
}

.testimonial-4 .slick-dotted.slick-slider {
	margin-bottom: 10px;
}

.testimonial-4 .slick-dots {
	z-index: 2000;
	bottom: 85px;
}

.f-testimonial-card {
	box-shadow: 0px 0px 20px 4px #e1e6e8;
}

.testimonial-4 .slick-dots li button:before {
	font-size: 21px;
	color: rgba(255, 255, 255, 0.4);
	opacity: 1;
}

.testimonial-4 .slick-dots li.slick-active button:before {
	color: #fff;
}

.testimonial-4 .slick-dots li {
	margin: 0 2px;
}

.homepage-content-box.partners-carousel .slick-dots {
	position: relative;
}

@media screen and (max-width: 991px) {
	.f-testimonial-card {
		box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.07);
	}

	.f-testimonial-slider-large {
		margin-top: 0px;
	}

	.testimonials-carousel {
		top: 70px;
		position: relative;
	}

	.testimonial-4 .slick-dots {
		bottom: 4px;
	}
}

@media screen and (max-width: 479px) {
	.main.slider {
		margin-top: 0;
	}

	.testimonial-4 .slick-dots {
		bottom: 45px;
	}

	.f-testimonial-card-outer {
		padding-top: 13px;
		padding-bottom: 0;
	}

	.testimonial-4 {
		padding-bottom: 55px;
	}

	.f-testimonial-card {
		min-height: 130px;
	}

	.testimonial-4 .slick-dotted.slick-slider {
		padding-bottom: 75px;
	}

	.testimonial-4 .slick-dots {
		bottom: 35px;
		display: flex;
		margin-left: 12px;
	}

	.testimonials-carousel {
		top: 60px;
		margin-top: -70px;
	}

	.f-testimonial-right-a,
	.f-testimonial-left-a {
		bottom: 10px;
	}

	.homepage-content-box.partners-carousel .slick-dotted.slick-slider {
		margin-bottom: 20px;
	}

	.homepage-content-box.partners-carousel .slick-slide,
	.homepage-content-box.partners-carousel .slick-track {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.homepage-content-box.partners-carousel .slick-dots {
		bottom: 0px;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

#content {
	background-color: #cde8f64d;
}

.inside-page-content-left-right {
	background-color: #cde8f64d;
}

.breadcrumbs-list-2 li:last-child .breadcrumb-divider-2 {
	display: none;
}

.breadcrumbs-list-2 li:last-child a:hover,
.breadcrumbs-list-2 li:last-child a {
	opacity: 1;
	color: #333;
	border-bottom-style: none;
	font-weight: 400;
	text-decoration: none;
}

.left-navigtaon-list {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.left-nav-link-5.active {
	border-left: 4px solid var(--red2);
	color: #fff;
	padding-left: 0.5rem;
	padding-right: 0;
	text-decoration: underline;
}

.left-nav-link-5.grandchild-link.active {
	color: #fff;
	border-left-color: #8ab0dd80;
	padding-left: 0.7rem;
}

.left-nav-link-5.greatgrandchild-link.active {
	border-left: 4px solid var(--red2);
	color: #fff;
	padding-left: 1rem;
}

.no-columns .inside-hero-section-2,
.no-columns .div-block-189 {
	max-width: 780px;
	width: 65%;
}

@media screen and (max-width: 991px) {
	.no-columns .inside-hero-section-2,
	.no-columns .div-block-189 {
		max-width: 100%;
		width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.element-intro-header-wrap {
		margin-top: 27px;
	}
}

/************** Right Sidebar ********************/
/*************************************************/
.right-nav-2 td img {
	max-width: none;
}

.right-nav-2 .box {
	border-top: 1px #00000026;
	margin-bottom: 2.441em;
	margin-left: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	font-family: Poppins, sans-serif;
}

.right-nav-2 .box.tools input[type="text"] {
	width: 100%;
}

.right-nav-2 .box p {
	color: #333;
	margin-bottom: 1rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
}

.right-nav-2 .box h4,
.right-nav-2 .box h3 {
	color: var(--blue);
	margin-top: 0;
	margin-bottom: 0.75em;
	font-family: Merriweather, serif;
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}

.right-nav-2 .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-nav-2 .box .contentbox_item_image {
	width: 24px;
}

.right-nav-2 .box.documents td:first-child {
	padding-right: 7px;
}

.right-nav-2 .box.tools td:first-child img {
	margin-right: 5px;
}

.right-nav-2 a {
	color: #333;
	border: 0 #0000;
	border-bottom: 0 solid #3333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: underline;
	transition: all 0.2s;
}

.right-nav-2 a:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-style: none;
	border-bottom-color: #0000;
	text-decoration: none;
}

@media (max-width: 991px) {
	.right-nav-2 .box {
		margin-left: 0px;
	}
}


/*************** Left Sidebar ********************/
/************************************************/
.left-sidebar .box {
	border-top: 1px #00000026;
	margin-bottom: 2.441em;
	margin-left: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	font-family: Poppins, sans-serif;
}

.left-sidebar .box,
.left-sidebar .box p {
	color: #333;
	margin-bottom: 0;
	font-size: 1rem;
	line-height: 26px;
}

.left-sidebar .box h2 {
	color: var(--blue);
	margin-top: 0;
	font-family: Merriweather, serif;
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 26px;
}

.left-sidebar a {
	color: #333;
	border: 0 #0000;
	border-bottom: 0 solid #3333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: underline;
	transition: all 0.2s;
}

.left-sidebar a:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-style: none;
	border-bottom-color: #0000;
	text-decoration: none;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.div-block-188 a {
	color: #333;
	border: 0 #0000;
	border-bottom: 0 solid #3333;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem;
	text-decoration: underline;
	transition: all 0.2s;
}

.div-block-188 a:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-style: none;
	border-bottom-color: #0000;
	text-decoration: none;
}

h1 {
	color: var(--blue);
	text-transform: capitalize;
	margin-top: 0;
	font-family: Merriweather, serif;
	font-size: 45px;
	font-weight: 700;
	line-height: 55px;
}

h2 {
	color: var(--blue);
	font-family: Merriweather, serif;
	font-size: 32px;
	font-weight: 700;
}

h3 {
	color: var(--blue);
	font-family: Merriweather, serif;
	font-weight: 700;
}

h4 {
	color: var(--blue);
	font-family: Merriweather, serif;
}

h5 {
	color: var(--blue);
	font-family: Merriweather, serif;
}

h6 {
	color: var(--blue);
	font-family: Merriweather, serif;
}

@media screen and (max-width: 767px) {
	h1 {
		margin-bottom: 0;
		font-size: 35px;
		line-height: 45px;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 25px;
	}
}

h1 a,
.div-block-188 h1 a {
	color: var(--blue);
	text-align: left;
	border-bottom: 0 solid #3333;
	font-family: Merriweather, serif;
	font-size: inherit;
	font-weight: 700;
	line-height: inherit;
	text-decoration: underline;
	transition: all 0.2s;
}

h2 a,
.div-block-188 h2 a {
	color: var(--blue);
	text-align: left;
	border-bottom: 0 solid #3333;
	font-family: Merriweather, serif;
	font-size: 32px;
	font-weight: 700;
	line-height: 36px;
	text-decoration: underline;
	transition: all 0.2s;
}

h3 a,
.div-block-188 h3 a {
	color: var(--blue);
	border-bottom: 0 solid #3333;
	font-family: Merriweather, serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 30px;
	text-decoration: underline;
	transition: all 0.2s;
}

h4 a,
.div-block-188 h4 a {
	color: var(--blue);
	border-bottom: 0 solid #3333;
	font-family: Merriweather, serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: underline;
	transition: all 0.2s;
}

h5 a,
.div-block-188 h5 a {
	color: var(--blue);
	text-transform: uppercase;
	border-bottom: 0 solid #3333;
	font-family: Merriweather, serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-decoration: underline;
	transition: all 0.2s;
}

h6 a,
.div-block-188 h6 a {
	color: var(--blue);
	text-transform: uppercase;
	border-bottom: 0 solid #3333;
	font-family: Merriweather, serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;
	text-decoration: underline;
	transition: all 0.2s;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.div-block-188 h1 a:hover,
.div-block-188 h2 a:hover,
.div-block-188 h3 a:hover,
.div-block-188 h4 a:hover,
.div-block-188 h5 a:hover,
.div-block-188 h6 a:hover {
	color: #0e266b;
	background-color: #0000;
	border-bottom-color: #0000;
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	h2 a,
	.div-block-188 h2 a {
		font-size: 1.75rem;
	}

	h3 a,
	.div-block-188 h3 a {
		font-size: 1.5rem;
	}

	h4 a,
	.div-block-188 h4 a {
		font-size: 1.25rem;
	}

	h5 a,
	.div-block-188 h5 a {
		font-size: 1.125rem;
	}
}

.div-block-188 p {
	color: #333;
	margin-bottom: 1rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	text-decoration: none;
}

.div-block-188 li {
	color: #333;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	border: 1px solid transparent !important;
	color: #fff !important;
	background-color: #d2232a !important;
	letter-spacing: 0;
	text-transform: uppercase;
	border-radius: 5px;
	padding: 9px 12px;
	font-family: Merriweather, serif;
	font-size: 14px;
	font-weight: 400;
	width: auto;
	text-decoration: none !important;
	margin: 5px;
	cursor: pointer;
	transition: all 0.1s ease;
	padding-right: 40px;
	background-image: url('/local/public/shared/assets/images/arrow-white.png'), linear-gradient(to left, #d2232a 50%, #a02226 50%);
	background-position: calc(100% - 8px) center, right;
	background-repeat: no-repeat;
	background-size: 25px, 200% 100%;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	background-color: #a02226 !important;
	background-position: calc(100% - 3px) center, left;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	border: 1px solid transparent !important;
	color: #fff !important;
	background-color: #d2232a !important;
	letter-spacing: 0;
	text-transform: uppercase;
	border-radius: 5px;
	padding: 9px 12px;
	font-family: Merriweather, serif;
	font-size: 14px;
	font-weight: 400;
	width: auto;
	text-decoration: none !important;
	margin: 5px;
	cursor: pointer;
	transition: all 0.1s ease;
	background-image: linear-gradient(to left, #d2232a 50%, #a02226 50%);
	background-position: right;
	background-repeat: no-repeat;
	background-size: 200% 100%;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	color: #fff !important;
	background-color: #a02226 !important;
	background-position: left;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	border: 1px solid transparent !important;
	color: #fff !important;
	background-color: #007bbd !important;
	letter-spacing: 0;
	text-transform: uppercase;
	border-radius: 5px;
	padding: 9px 12px;
	font-family: Merriweather, serif;
	font-size: 14px;
	font-weight: 400;
	width: auto;
	text-decoration: none !important;
	margin: 5px;
	cursor: pointer;
	transition: all 0.1s ease;
	padding-right: 40px;
	background-image: url('/local/public/shared/assets/images/arrow-white.png'), linear-gradient(to left, #007bbd 50%, #005c91 50%);
	background-position: calc(100% - 8px) center, right;
	background-repeat: no-repeat;
	background-size: 25px, 200% 100%;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #fff !important;
	background-color: #005c91 !important;
	background-position: calc(100% - 3px) center, left;
}

/* messages */
#message.success {
	background-color: #11b91e40;
	border-left: 6px solid #11b91e;
	font-size: 1.1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.2rem;
}

#message.error {
	background-color: #fd363626;
	border-left: 6px solid #c70616;
	font-size: 1.1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.2rem;
	color: #000;
}

#message.success p {
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.2rem;
	color: #000;
}

#message.error p {
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.2rem;
	color: #000;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #005c91;
	color: white;
	font-family: Poppins, sans-serif;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #005c91;
	color: white;
	font-family: Poppins, sans-serif;
	font-weight: 300;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
	font-weight: 300;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
	font-family: Poppins, sans-serif;
	color: #000;
	font-weight: 300;
}

table.styled.striped tr:nth-child(even) {
	background: #f0f0f0;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

.gr-form {
	background-color: #fff;
}

hr {
	background-color: #427d0e66;
	background-image: linear-gradient(-90deg, var(--blue), var(--blue4));
	color: #197974;
	height: 2px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	line-height: 20px;
	border: 0px;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
	margin-bottom: 10px;
	background-color: #fff;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

.inside-row option {
	font-weight: 600;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border: 0px solid var(--blue);
	color: #333;
	background-color: #e4f3fa00;
	border-radius: 8px;
	margin-top: 0;
	margin-bottom: 30px;
	font-size: 1.25rem;
	font-style: italic;
	font-weight: 300;
	line-height: 2.2rem;
	padding: 20px 20px;
	background-image: url('/20181016082423/assets/images/lines-2b.svg'), url('/20181016082423/assets/images/lines-2b.svg');
	background-position: center top, center bottom;
	background-repeat: repeat-x;
	background-size: 9px;
}

.grip-grid > div {
	background-color: transparent !important;
}

blockquote p {
	font-size: inherit !important;
	line-height: inherit !important;
	font-family: inherit !important;
	color: inherit !important;
	font-weight: inherit !important;
}

blockquote p:last-child {
	margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

/********************** Footer **********************/
/****************************************************/
.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
}

@media screen and (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}
}

@media screen and (max-width: 479px) {
	#GRIPFooterLogo {
		margin-bottom: 10px;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

.ui-form-label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
.login-row__login #account_module.form .form_item .label,
.login-row__login #account_module.form .form_item.required .label {
	width: auto;
}

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (min-width: 992px) {
	#account_module.form .required_message {
		margin-right: 120px;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* submission forms */
#submissionforms_module {
	text-align: left;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper {
	margin-bottom: 30px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 1rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list h2 {
	font-size: 1.8rem;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#faqs_module.cms_list ul li a {
	text-decoration: none;
	border-bottom: 1px solid transparent;
	font-size: 1.2rem;
	margin-top: 20px;
	display: flex;
	padding: 10px 20px;
	justify-content: space-between;
	background-color: #f0f0f0;
}

#faqs_module.cms_list ul li a:after {
	width: 25px;
	min-width: 25px;
	height: 25px;
	line-height: 24px;
	font-size: 24px;
	text-align: center;
	color: #000;
	font-weight: 400;
	content: '>';
}

#faqs_module.cms_list ul li a[data-open="true"]:after {
	transform: rotate(90deg);
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 0px;
}

#faqs_module.cms_list .cms_list_item .description {
	padding: 10px 20px;
	background-color: #f0f0f0;
	padding-top: 0px;
}

#faqs_module.cms_list .cms_list_item .description p:last-child {
	margin-bottom: 0px;
}

#faqs_module.cms_list .cms_list_item .description a {
	display: inline-block;
	font-size: 14px;
	padding-right: 30px;
	padding-left: 20px;
}

#faqs_module.cms_list .cms_list_item .description a:after {
	display: none;
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* mailing lists */
#mailinglists-module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 12px 0 20px;
}


/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}
